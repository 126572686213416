import React from 'react';

export function WelcomePage(props) {
    return (
        <div className="card bg-light">
            <div className="container card-body">
                <h2>Welcome to SeasonManager!</h2>
                <p>The latest and greatest Dash Season Warehouse Manager</p>
                <p><a href="/signin">Sign in to get started!</a></p>
            </div>
        </div>
    );
}

export function AboutPage() {
    return (
        <div className="card bg-warning bg-gradient">
            <div className="container card-body">
                <h2>About Dash Season Manager</h2>
                <p>This app should make it more efficient to manage seasons in the dash warehouse</p>
            </div>
        </div>
    );
}

export function ErrorPage() {
    return <p className="alert alert-danger">Page not found</p>;
}