import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import FacilitySiteRow from "./FacilitySiteRow";
import { ConstructionOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export default function LocationRow(props) {
  const dim_facilityID = props.dim_facilityID;
  const facilityID = props.facilityID;
  const location = props.location;
  const facilities = props.location.facilities;
  
  const [open, setOpen] = React.useState(false);

  const [showLocationNotes, setShowLocationNotes] = useState(false);
  const [showLocationEmail, setShowLocationEmail] = useState(false);
  const [locationisLKActive, setLocationisLKActive] = useState(
    props.locationisLKSIActive
  );
  const [locationLKSIEmail, setLocationLKSIEmail] = useState(
    props.locationLKSIEmail
  );
  const [locationLKSINotes, setLocationLKSINotes] = useState(
    props.locationLKSINotes
  );

  const handleUpdateLKFieldsDimFacility_Location =
    props.handleUpdateLKFieldsDimFacility_Location;

  const handleAddUpdateFacilitySite = props.handleAddUpdateFacilitySite;
  const handleRemoveFacilitySite = props.handleRemoveFacilitySite;

  const handleShowLocationNotes = () => setShowLocationNotes(true);
  const handleCloseLocationeNotes = () => setShowLocationNotes(false);
  const handleShowLocationEmail = () => setShowLocationEmail(true);
  const handleCloseLocationEmail = () => setShowLocationEmail(false);

  const handleLocationisLKActive = (event) => {
    const newSetting = event.target.checked;
    setLocationisLKActive(newSetting);
  };

  const handleEmailChanged = (event) => {
    const newEmail = event.target.value;
    setLocationLKSIEmail(newEmail);
  };

  const handleLocationNotesChanged = (event) => {
    const newNotes = event.target.value;
    setLocationLKSINotes(newNotes);
  };

  const handleLocationUpdated = (e) => {
    const locationRowData = {
      dim_facilityID: dim_facilityID,
      facilityID: facilityID,
      locationLKSIEmail: locationLKSIEmail,
      locationisLKSIActive: locationisLKActive,
      locationLKSINotes: locationLKSINotes,
    };

    handleUpdateLKFieldsDimFacility_Location(locationRowData);
  };

  // let tempkey = "";

  const facilitiesArray = facilities.map((facility) => {

    const element = (
      <FacilitySiteRow
        facility={facility}
        // key={facility.facility_id + facility.facility}
        key={facility.facilitysource + '-' + facility.facility_id + '-' + facility.facility_site_id}
        // key={tempkey}
        handleAddUpdateFacilitySite={handleAddUpdateFacilitySite}
        handleRemoveFacilitySite={handleRemoveFacilitySite}
      />
    );

    // tempkey = "";
    return element;
  });

  const emptyFacilityObj = {
    facility_site_id: 0,
    facilityName: location.location,
    facility_numFields: 1,
    space: 0,
    indooravailable: 0,
    outdooravailable: 0,
    facilityactive: 0,
    facilitysource: location.locationsource,
    facility_id: location.facilityID,
  };

  // console.log("emptyFacilityObj", emptyFacilityObj);

  const extraRow = (
    <FacilitySiteRow
      facility={emptyFacilityObj}
      key={emptyFacilityObj.facilitysource + '-' + emptyFacilityObj.facility_id + '-' + emptyFacilityObj.facility_site_id}
      handleAddUpdateFacilitySite={handleAddUpdateFacilitySite}
      handleRemoveFacilitySite={handleRemoveFacilitySite}
    />
  );

  facilitiesArray.push(extraRow);

  const theme = createTheme();

  theme.typography.locationRow = {
    fontSize: "1.1rem",
  };

  theme.typography.facilityRow = {
    fontSize: "1.1rem",
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center" style={{ width: "10vw" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <ThemeProvider theme={theme}>
            <Typography variant="locationRow">{location.location}</Typography>
          </ThemeProvider>
        </TableCell>
        <TableCell component="th" scope="row">
          <ThemeProvider theme={theme}>
            <Typography variant="locationRow">
              Location Active:
              <Checkbox
                value={locationisLKActive == 1}
                checked={locationisLKActive == 1}
                onChange={(e) => handleLocationisLKActive(e, {})}
              ></Checkbox>
            </Typography>
          </ThemeProvider>
        </TableCell>
        <TableCell>
          <div>
            <ThemeProvider theme={theme}>
              <Typography variant="locationRow">
                <TextField
                  id="outlined-helperText"
                  fullWidth
                  value={locationLKSIEmail}
                  size="small"
                  helperText={location.location + " Email"}
                  onClick={handleShowLocationEmail}
                ></TextField>
              </Typography>
            </ThemeProvider>
          </div>

          <Modal show={showLocationEmail} onHide={handleCloseLocationEmail}>
            <Modal.Header closeButton>
              <Modal.Title>{location.location} Email</Modal.Title>
            </Modal.Header>
            <Modal.Body className="locationLKSIEmailModal">
              <LocationEmailTextArea
                placeholder="Email(s)…"
                defaultValue={locationLKSIEmail}
                onChange={handleEmailChanged}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLocationEmail}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </TableCell>
        {/* <TableCell></TableCell> */}
        <TableCell>
          <ThemeProvider theme={theme}>
            <Typography variant="locationRow">
              <div onClick={handleShowLocationNotes}>
                <TextField
                  id="outlined-helperText"
                  fullWidth
                  value={locationLKSINotes}
                  size="small"
                  helperText={location.location + " Notes"}
                  onClick={handleShowLocationNotes}
                ></TextField>
              </div>
            </Typography>
          </ThemeProvider>
          <Modal show={showLocationNotes} onHide={handleCloseLocationeNotes}>
            <Modal.Header closeButton>
              <Modal.Title>{location.location} Notes</Modal.Title>
            </Modal.Header>
          <Modal.Body className="locationLKSINotesModal">
              <LocationNotesTextArea
                placeholder="Notes"
                defaultValue={locationLKSINotes}
                onChange={handleLocationNotesChanged}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLocationeNotes}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </TableCell>

        <TableCell align="center">
          <Button
            variant="contained"
            onClick={(e) => handleLocationUpdated(e, {})}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="locationRow">Update</Typography>
            </ThemeProvider>
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Facility</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Fields</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Space</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Indoor</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Outdoor</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <ThemeProvider theme={theme}>
                        <Typography variant="facilityRow">Active</Typography>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell align="center">
                      <InputLabel variant="standard">
                        <ThemeProvider theme={theme}>
                          <Typography variant="facilityRow">Action</Typography>
                        </ThemeProvider>
                      </InputLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{facilitiesArray}</TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

{
  /* <LocationNotesModal
show={showLocationNotes}
onHide={handleCloseLocationNotes}
location={location.location}
locationLKSINotes={locationLKSINotes}
defaultValue={locationLKSINotes}
onChange={handleLocationNotesChanged}
onClick={handleCloseLocationNotes}
></LocationNotesModal>
export function LocationNotesModal(props) {
  return (
    <Modal
      show={props.showLocationNotes}
      onHide={props.handleCloseLocationeNotes}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.location} Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="locationLKSINotesModal">
        <LocationNotesTextArea
          placeholder="Notes"
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClick}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
} */
}
const StyledTextArea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 90%;`
);

export function LocationNotesTextArea(props) {
  return (
    <StyledTextArea
      placeholder="Notes"
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      autoFocus
      onFocus={function(e) {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
      }}
    ></StyledTextArea>
  );
}

export function LocationEmailTextArea(props){
  return (
    <StyledTextArea
    placeholder="Email(s)"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    autoFocus
    onFocus={function(e) {
      var val = e.target.value;
      e.target.value = '';
      e.target.value = val;
    }}
    ></StyledTextArea>
  );
  }
