import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from "@mui/material/CircularProgress";

import AuditRow from './AuditRow';
import * as React from 'react';

export default function AuditList(props) {

  const propsSeasonArray = props.seasonsArray;
  const seasonDataForQueries = props.seasonDataForQueries;

  const handleUpdateLocationDimFacility = props.handleUpdateLocationDimFacility;
  const seasonsArray = [...propsSeasonArray];

  const deleteSqlRow = props.deleteSqlRow;
  const addOrUpdateSQLRow = props.addOrUpdateSQLRow;
  const updateInAsyncronousManner = props.updateInAsyncronousManner;
  const comcodes = props.comcodes;

  const [acceptedFilter, setAcceptedFilter] = useState("All");
  const [addedFilter, setAddedFilter] = useState("All");
  const [TrackingYearFilter, setTrackingYearFilter] = useState("All");
  const [TrackingSeasonFilter, setTrackingSeasonFilter] = useState("All");
  const [comCodeFilter, setComCodeFilter] = useState("All");
  const [comIDFilter, setComIDFilter] = useState("All");
  const [facilityNameFilter, setFacilityNameFilter] = useState("All");
  const [ProgramTypeFilter, setProgramTypeFilter] = useState("All");

  const handleSelectAccepted = (e) => {
    console.log("Selected a Filter", e.target.value);
    setAcceptedFilter(e.target.value)
    // updateInAsyncronousManner();
    console.log("handleSelectAccepted within SeasonMaterial")
  }

  const handleSelectAdded = (e) => {
    console.log("Selected a Filter", e.target.value);
    setAddedFilter(e.target.value)
    // updateInAsyncronousManner();

  }

  const handleSelectTrackingSeason = (e) => {
    console.log("Selected a Filter", e.target.value);
    setTrackingSeasonFilter(e.target.value)
    // updateInAsyncronousManner();

  }

  const handleSelectTrackingYear = (e) => {
    console.log("Selected a Filter", e.target.value);
    setTrackingYearFilter(e.target.value)
    // updateInAsyncronousManner();

  }

  const handleSelectComCode = (e) => {
    console.log("Selected a Filter", e.target.value);
    setComCodeFilter(e.target.value)
    // updateInAsyncronousManner();
  }

  const handleSelectComID = (e) => {
    console.log("Selected a Filter", e.target.value);
    setComIDFilter(e.target.value)
    // updateInAsyncronousManner();
  }

  const handleSelectFacilityName = (e) => {
    console.log("Selected a Filter", e.target.value);
    setFacilityNameFilter(e.target.value)
    // updateInAsyncronousManner();
  }

  const handleSelectProgramType = (e) => {
    console.log("Selected a Filter", e.target.value);
    setProgramTypeFilter(e.target.value)
    // updateInAsyncronousManner();
  }

  function filterBy(seasonObj) {
    if (
      ((acceptedFilter !== "All") && ((acceptedFilter === 'true' && !seasonObj.accepted) || (acceptedFilter === 'false' && seasonObj.accepted)))
      || ((addedFilter !== "All") && ((addedFilter === 'true' && !seasonObj.added) || (addedFilter === 'false' && seasonObj.added)))
      || ((TrackingYearFilter !== "All") && ((TrackingYearFilter !== seasonObj.TrackingYear)))
      || ((TrackingSeasonFilter !== "All") && ((TrackingSeasonFilter !== seasonObj.TrackingSeason)))
      || ((comCodeFilter !== "All") && ((comCodeFilter !== seasonObj.comCode)))
      || ((comIDFilter !== "All") && ((comIDFilter !== seasonObj.comID)))
      || ((facilityNameFilter !== "All") && ((facilityNameFilter !== seasonObj.facilityName)))
      || ((ProgramTypeFilter !== "All") && ((Number(ProgramTypeFilter) !== Number(seasonObj.ProgramType))))
    ) {
      return false
    }
    else return true
  }
  const filteredSeasonsArray = seasonsArray.filter(filterBy);

  const newSeasonComponentArray = filteredSeasonsArray.map((seasonObj) => {

    let locationNotes = undefined;
    let dim_facilityID = undefined;
    const index = comcodes.findIndex(element => {
      if (element.comID == seasonObj.comID) {
        return true
      }
      return false;
    })

    if (index === -1) {

      console.log("Didn't find that comID", seasonObj.comID);
    }

    else {

      const locationIndex = comcodes[index].locations.findIndex(element => {

        if (element.location === seasonObj.facilityName) {
          return true;
        }
        return false;
      })

      if (locationIndex === -1) {

      }
      else {
        locationNotes = comcodes[index].locations[locationIndex].locationLKSINotes;
        // console.log("comcodes[index].locations[locationIndex]", comcodes[index].locations[locationIndex]);
        dim_facilityID = comcodes[index].locations[locationIndex].dim_facilityID;
        // console.log("dim_facilityID: ", dim_facilityID);
      }

    }


    const priorSeasonArray = seasonDataForQueries.filter((element) => {


      if (element.comID == seasonObj.comID && element.facilityName === seasonObj.facilityName && element.TrackingYear == seasonObj.TrackingYear - 1) {
        return true;
      }

      return false;
    })


    const transformed = (
      <AuditRow seasonData={seasonObj} key={seasonObj.sKey + seasonObj.seasonID} seasonDataForQueries={seasonDataForQueries}
        locationNotes={locationNotes} dim_facilityID={dim_facilityID} priorSeasonArray={priorSeasonArray}
        handleUpdateLocationDimFacility={handleUpdateLocationDimFacility} updateInAsyncronousManner={updateInAsyncronousManner}
        deleteSqlRow={deleteSqlRow} addOrUpdateSQLRow={addOrUpdateSQLRow}
      />
    )

    return transformed;

  })

  console.log(seasonDataForQueries);



  const newSeasonComponentArrayRefresh = [...newSeasonComponentArray]

  function compareComcode(a, b) {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  }

  function compareComID(a, b) {
    let firstComID = a.com
    if (Number(a.key) < Number(b.key)) {
      return -1;
    }
    if (Number(a.key) > Number(b.key)) {
      return 1;
    }
    return 0;
  }

  function compareFacilityName(a, b) {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  }

  const uniqueComCodeFilters = [];
  const comCodeFilterOptions = filteredSeasonsArray.map((seasonObj) => {
    const { comCode } = seasonObj;
    const optionElement = <option value={comCode} key={comCode}>{comCode}</option>
    return optionElement
  }).filter((optionElement) => {
    const isDuplicate = uniqueComCodeFilters.includes(optionElement.key);
    if (!isDuplicate) {
      uniqueComCodeFilters.push(optionElement.key);
      return true;
    }
    return false;
  }).sort(compareComcode);

  const uniqueComIDFilters = [];
  const comIDFilterOptions = filteredSeasonsArray.map((seasonObj) => {
    const { comID } = seasonObj;
    const optionElement = <option value={comID} key={comID}>{comID}</option>
    return optionElement
  }).filter((optionElement) => {
    const isDuplicate = uniqueComIDFilters.includes(optionElement.key);
    if (!isDuplicate) {
      uniqueComIDFilters.push(optionElement.key);
      return true;
    }
    return false;
  }).sort(compareComID);

  const uniqueFacilityFilterNames = [];
  const facilityNameFilterOptions = filteredSeasonsArray.map((seasonObj) => {
    const { facilityName, sKey } = seasonObj;
    const optionElement = <option value={facilityName} key={facilityName}>{facilityName}</option>
    return optionElement
  }).filter((optionElement) => {
    const isDuplicate = uniqueFacilityFilterNames.includes(optionElement.key);
    if (!isDuplicate) {
      uniqueFacilityFilterNames.push(optionElement.key);
      return true;
    }
    return false;
  }).sort(compareFacilityName);

  const uniqueTrackingYearFilters = [];
  const TrackingYearFilterOptions = filteredSeasonsArray.map((seasonObj) => {
    const { TrackingYear } = seasonObj;
    const optionElement = <option value={TrackingYear} key={TrackingYear}>{TrackingYear}</option>
    return optionElement
  }).filter((optionElement) => {
    const isDuplicate = uniqueTrackingYearFilters.includes(optionElement.key);
    if (!isDuplicate) {
      uniqueTrackingYearFilters.push(optionElement.key);
      return true;
    }
    return false;
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  if (seasonsArray.length === 0) {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 1500 }}>
          <Table stickyHeader>
            <TableHead aria-label="sticky table" className="thead-dark">
              <StyledTableRow>
                <TableCell align="center">
                  <InputLabel variant="standard">
                    sKey
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center" >

                  <InputLabel variant="standard">
                    comCode
                  </InputLabel>
                  <NativeSelect
                    name="comCode"
                    id="comCode"
                    defaultValue={comCodeFilter}
                    onChange={handleSelectComCode}>
                    <option value='All'>All</option>
                    {comCodeFilterOptions}
                  </NativeSelect>


                </TableCell>

                <TableCell align="center" >

                  <InputLabel variant="standard">
                    comID
                  </InputLabel>
                  <NativeSelect
                    name="comID"
                    id="comID"
                    defaultValue={comIDFilter}
                    onChange={handleSelectComID}>
                    <option value='All'>All</option>
                    {comIDFilterOptions}
                  </NativeSelect>


                </TableCell>
                <TableCell align="center" >

                  <InputLabel variant="standard">
                    facilityName
                  </InputLabel>
                  <NativeSelect
                    name="facilityName"
                    id="facilityName"
                    defaultValue={facilityNameFilter}
                    onChange={handleSelectFacilityName}>
                    <option value='All'>All</option>
                    {facilityNameFilterOptions}
                  </NativeSelect>

                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    SeasonID
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    SeasonName
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    SeasonStart
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    SeasonEnd
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    SeasonDesc
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    PYSeasonID
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">
                    PYSeasonName
                  </InputLabel>
                  _
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard" >
                    Program Type
                  </InputLabel>
                  <NativeSelect
                    defaultValue={ProgramTypeFilter}
                    onChange={handleSelectProgramType}
                  >
                    <option value='All'>All</option>
                    <option value={1}>LK</option>
                    <option value={2}>SI</option>
                    <option value={0}>Other</option>
                  </NativeSelect>

                </TableCell>

                <TableCell align="center" >

                  <InputLabel variant="standard">
                    SeasonTrack
                  </InputLabel>
                  <NativeSelect
                    defaultValue={TrackingSeasonFilter}
                    onChange={handleSelectTrackingSeason}
                  >
                    <option value='All'>All</option>
                    <option value={1}>Spring</option>
                    <option value={2}>Summer</option>
                    <option value={3}>Fall</option>
                    <option value={4}>Winter</option>
                    <option value={0}>Other</option>
                  </NativeSelect>

                </TableCell>

                <TableCell align="center" >

                  <InputLabel variant="standard">
                    YearTrack
                  </InputLabel>
                  <NativeSelect
                    defaultValue={TrackingYearFilter}
                    onChange={handleSelectTrackingYear}>
                    <option value='All'>All</option>
                    {TrackingYearFilterOptions}
                  </NativeSelect>

                </TableCell>

                <TableCell align="center" >

                  <InputLabel variant="standard" >
                    LK or SI
                  </InputLabel>
                  <NativeSelect
                    name="Added" id="Added"
                    defaultValue={addedFilter}
                    onChange={handleSelectAdded}
                  >
                    <option value='All'>All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </NativeSelect>

                </TableCell>
                <TableCell align="center" >

                  <InputLabel variant="standard" >
                    Row Saved
                  </InputLabel>
                  <NativeSelect
                    name="Accepted" id="Accepted"
                    defaultValue={acceptedFilter}
                    onChange={handleSelectAccepted}
                  >
                    <option value="All">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </NativeSelect>

                </TableCell>

                <TableCell align="center" >
                  <InputLabel variant="standard">
                    Action
                  </InputLabel>
                  _
                </TableCell>


              </StyledTableRow>
            </TableHead>
            </Table>
        </TableContainer>

        <CircularProgress />
      </Paper>)
  }



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 1500 }}>
        <Table stickyHeader>
          <TableHead aria-label="sticky table" className="thead-dark">
            <StyledTableRow>
              <TableCell align="center">
                <InputLabel variant="standard">
                  sKey
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center" >

                <InputLabel variant="standard">
                  comCode
                </InputLabel>
                <NativeSelect
                  name="comCode"
                  id="comCode"
                  defaultValue={comCodeFilter}
                  onChange={handleSelectComCode}>
                  <option value='All'>All</option>
                  {comCodeFilterOptions}
                </NativeSelect>


              </TableCell>

              <TableCell align="center" >

                <InputLabel variant="standard">
                  comID
                </InputLabel>
                <NativeSelect
                  name="comID"
                  id="comID"
                  defaultValue={comIDFilter}
                  onChange={handleSelectComID}>
                  <option value='All'>All</option>
                  {comIDFilterOptions}
                </NativeSelect>


              </TableCell>
              <TableCell align="center" >

                <InputLabel variant="standard">
                  facilityName
                </InputLabel>
                <NativeSelect
                  name="facilityName"
                  id="facilityName"
                  defaultValue={facilityNameFilter}
                  onChange={handleSelectFacilityName}>
                  <option value='All'>All</option>
                  {facilityNameFilterOptions}
                </NativeSelect>

              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  SeasonID
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  SeasonName
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  SeasonStart
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  SeasonEnd
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  SeasonDesc
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  PYSeasonID
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">
                  PYSeasonName
                </InputLabel>
                _
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard" >
                  Program Type
                </InputLabel>
                <NativeSelect
                  defaultValue={ProgramTypeFilter}
                  onChange={handleSelectProgramType}
                >
                  <option value='All'>All</option>
                  <option value={1}>LK</option>
                  <option value={2}>SI</option>
                  <option value={0}>Other</option>
                </NativeSelect>

              </TableCell>

              <TableCell align="center" >

                <InputLabel variant="standard">
                  SeasonTrack
                </InputLabel>
                <NativeSelect
                  defaultValue={TrackingSeasonFilter}
                  onChange={handleSelectTrackingSeason}
                >
                  <option value='All'>All</option>
                  <option value={1}>Spring</option>
                  <option value={2}>Summer</option>
                  <option value={3}>Fall</option>
                  <option value={4}>Winter</option>
                  <option value={0}>Other</option>
                </NativeSelect>

              </TableCell>

              <TableCell align="center" >

                <InputLabel variant="standard">
                  YearTrack
                </InputLabel>
                <NativeSelect
                  defaultValue={TrackingYearFilter}
                  onChange={handleSelectTrackingYear}>
                  <option value='All'>All</option>
                  {TrackingYearFilterOptions}
                </NativeSelect>

              </TableCell>

              <TableCell align="center" >

                <InputLabel variant="standard" >
                  LK or SI
                </InputLabel>
                <NativeSelect
                  name="Added" id="Added"
                  defaultValue={addedFilter}
                  onChange={handleSelectAdded}
                >
                  <option value='All'>All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </NativeSelect>

              </TableCell>
              <TableCell align="center" >

                <InputLabel variant="standard" >
                  Row Saved
                </InputLabel>
                <NativeSelect
                  name="Accepted" id="Accepted"
                  defaultValue={acceptedFilter}
                  onChange={handleSelectAccepted}
                >
                  <option value="All">All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </NativeSelect>

              </TableCell>

              <TableCell align="center" >
                <InputLabel variant="standard">
                  Action
                </InputLabel>
                _
              </TableCell>


            </StyledTableRow>
          </TableHead>
          <TableBody>
            {newSeasonComponentArrayRefresh}
          </TableBody>
        </Table>

      </TableContainer>
    </Paper>
  )
}


