import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import SeasonRow from "./SeasonRow";
import * as React from "react";

export default function SeasonList(props) {
  const propsSeasonArray = props.seasonsArray;
  const handleLKCountUpdate = props.handleLKCountUpdate;
  const handleSICountUpdate = props.handleSICountUpdate;
  const seasonDataForQueries = [];
  const seasonsArray = [...propsSeasonArray];

  const comcodes = [];

  const [comCodeFilter, setComCodeFilter] = useState("All");
  const [comIDFilter, setComIDFilter] = useState("All");
  const [facilityNameFilter, setFacilityNameFilter] = useState("All");
  const [seasonTrackFilter, setSeasonTrackFilter] = useState([]);
  const [programTypeFilter, setProgramTypeFilter] = useState([]);
  const [yearTrackFilter, setYearTrackFilter] = useState([]);
  const [isInAsynch, setisInAsynch] = useState(false);


  const handleSelectComCode = (e) => {
    console.log("Selected a Filter", e.target.value);
    setComCodeFilter(e.target.value);
  };

  const handleSelectComID = (e) => {
    console.log("Selected a Filter", e.target.value);
    setComIDFilter(e.target.value);
  };

  const handleSelectFacilityName = (e) => {
    console.log("Selected a Filter", e.target.value);
    setFacilityNameFilter(e.target.value);
  };


  const handleChangeSeasonTrack = (event) => {
    const {
      target: { value },
    } = event;
    setSeasonTrackFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeProgramType = (event) => {
    const {
      target: { value },
    } = event;
    setProgramTypeFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("value: ", value)
  };

  const handleChangeYearTrack = (event) => {
    const {
      target: { value },
    } = event;
    setYearTrackFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function filterBy(seasonObj) {
    if (
      (comCodeFilter !== "All" && comCodeFilter !== seasonObj.comCode) ||
      (comIDFilter !== "All" && comIDFilter !== seasonObj.comID) ||
      (facilityNameFilter !== "All" && facilityNameFilter !== seasonObj.facilityName) ||
      (programTypeFilter.length !== 0 && !programTypeFilter.includes(seasonObj.ProgramType.toString())) ||
      (seasonTrackFilter.length !== 0 && !seasonTrackFilter.includes(seasonObj.TrackingSeason)) ||
      (yearTrackFilter.length !== 0 && !yearTrackFilter.includes(seasonObj.TrackingYear))
    ) {

      return false;
    } else {
      return true;
    }
  }


  const filteredSeasonsArray = seasonsArray.filter(filterBy);
  const newSeasonComponentArray = filteredSeasonsArray.map((seasonObj) => {
    let locationNotes = undefined;
    let dim_facilityID = undefined;

    if (comcodes) {
      const index = comcodes.findIndex((element) => {
        if (element.comID == seasonObj.comID) {
          return true;
        }
        return false;
      });
      if (index === -1) {

      } else {
        const locationIndex = comcodes[index].locations.findIndex((element) => {
          if (element.location === seasonObj.facilityName) {
            return true;
          }
          return false;
        });

        if (locationIndex === -1) {
        } else {
          locationNotes =
            comcodes[index].locations[locationIndex].locationLKSINotes;
          dim_facilityID =
            comcodes[index].locations[locationIndex].dim_facilityID;
        }
      }
    }

    const priorSeasonArray = seasonDataForQueries.filter((element) => {
      if (
        element.comID == seasonObj.comID &&
        element.facilityName === seasonObj.facilityName &&
        element.TrackingYear == seasonObj.TrackingYear - 1
      ) {
        return true;
      }

      return false;
    });


    const transformed = (
      <SeasonRow
        seasonData={seasonObj}
        key={seasonObj.sKey + seasonObj.seasonID}
        seasonDataForQueries={seasonDataForQueries}
        locationNotes={locationNotes}
        dim_facilityID={dim_facilityID}
        priorSeasonArray={priorSeasonArray}
        handleLKCountUpdate={handleLKCountUpdate}
        handleSICountUpdate={handleSICountUpdate}
      />
    );

    return transformed;
  });


  const newSeasonComponentArrayRefresh = [...newSeasonComponentArray];

  function compareComcode(a, b) {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  }

  function compareComID(a, b) {
    let firstComID = a.com;
    if (Number(a.key) < Number(b.key)) {
      return -1;
    }
    if (Number(a.key) > Number(b.key)) {
      return 1;
    }
    return 0;
  }

  function compareFacilityName(a, b) {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  }

  const uniqueComCodeFilters = [];
  const comCodeFilterOptions = filteredSeasonsArray
    .map((seasonObj) => {
      const { comCode } = seasonObj;
      const optionElement = (
        <option value={comCode} key={comCode}>
          {comCode}
        </option>
      );
      return optionElement;
    })
    .filter((optionElement) => {
      const isDuplicate = uniqueComCodeFilters.includes(optionElement.key);
      if (!isDuplicate) {
        uniqueComCodeFilters.push(optionElement.key);
        return true;
      }
      return false;
    })
    .sort(compareComcode);

  const uniqueComIDFilters = [];
  const comIDFilterOptions = filteredSeasonsArray
    .map((seasonObj) => {
      const { comID } = seasonObj;
      const optionElement = (
        <option value={comID} key={comID}>
          {comID}
        </option>
      );
      return optionElement;
    })
    .filter((optionElement) => {
      const isDuplicate = uniqueComIDFilters.includes(optionElement.key);
      if (!isDuplicate) {
        uniqueComIDFilters.push(optionElement.key);
        return true;
      }
      return false;
    })
    .sort(compareComID);

  const uniqueFacilityFilterNames = [];
  const facilityNameFilterOptions = filteredSeasonsArray
    .map((seasonObj) => {
      const { facilityName, sKey } = seasonObj;
      const optionElement = (
        <option value={facilityName} key={facilityName}>
          {facilityName}
        </option>
      );
      return optionElement;
    })
    .filter((optionElement) => {
      const isDuplicate = uniqueFacilityFilterNames.includes(optionElement.key);
      if (!isDuplicate) {
        uniqueFacilityFilterNames.push(optionElement.key);
        return true;
      }
      return false;
    })
    .sort(compareFacilityName);

  const seasonTrack = [
    {
      id: "1",
      value: "Spring"
    },
    {
      id: "2",
      value: "Summer"
    },
    {
      id: "3",
      value: "Fall"
    },
    {
      id: "4",
      value: "Winter"
    }
  ];


  const programType = [
    {
      id: "1",
      value: "LK"
    },
    {
      id: "2",
      value: "SI"
    },
    {
      id: "0",
      value: "Other"
    }

  ];

  const yearTrack = ["2023", "2022", "2021", "2020", "2019", "2018", "2017"];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // if (newSeasonComponentArrayRefresh.length < 1) {
  //   return <div> no data yet</div>;
  // }


  if (seasonsArray.length === 0) {

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 1500 }}>
          <Table stickyHeader>
            <TableHead aria-label="sticky table" className="thead-dark">
              <StyledTableRow>

                <TableCell align="center">
                  <InputLabel variant="standard">comCode</InputLabel>
                  <NativeSelect
                    name="comCode"
                    id="comCode"
                    defaultValue={comCodeFilter}
                    onChange={handleSelectComCode}
                  >
                    <option value="All">All</option>
                    {comCodeFilterOptions}
                  </NativeSelect>
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">comID</InputLabel>
                  <NativeSelect
                    name="comID"
                    id="comID"
                    defaultValue={comIDFilter}
                    onChange={handleSelectComID}
                  >
                    <option value="All">All</option>
                    {comIDFilterOptions}
                  </NativeSelect>
                </TableCell>
                <TableCell align="center">
                  <InputLabel variant="standard">Facility</InputLabel>
                  <NativeSelect
                    name="facilityName"
                    id="facilityName"
                    defaultValue={facilityNameFilter}
                    onChange={handleSelectFacilityName}
                  >
                    <option value="All">All</option>
                    {facilityNameFilterOptions}
                  </NativeSelect>
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">ID</InputLabel>_
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">Name</InputLabel>_
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">Start</InputLabel>_
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">End</InputLabel>_
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="standard">PY Name</InputLabel>_
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="demo-multiple-checkbox-label">
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={programTypeFilter}
                    onChange={handleChangeProgramType}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={

                      (selected) => {
                        selected.map((obj) => {

                          if (obj === 0) {
                            return "0".join(", ");
                          }
                          if (obj === 1) {
                            return "1".join(", ");
                          }
                          if (obj === 2) {
                            return "2".join(", ");
                          }

                        })
                      }
                    }

                  >
                    {programType.map((program) => (
                      <MenuItem key={program.id} value={program.id}>
                        <Checkbox
                          checked={programTypeFilter.indexOf(program.id) > -1}
                        />
                        <ListItemText primary={program.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="demo-multiple-checkbox-label">
                    SeasonTrack
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={seasonTrackFilter}
                    onChange={handleChangeSeasonTrack}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map((obj) => seasonTrack[obj - 1].value).join(", ")}

                  >
                    {seasonTrack.map((season) => (
                      <MenuItem key={season.id} value={season.id}>
                        <Checkbox
                          checked={seasonTrackFilter.indexOf(season.id) > -1}
                        />
                        <ListItemText primary={season.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <InputLabel variant="demo-multiple-checkbox-label">
                    YearTrack
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={yearTrackFilter}
                    onChange={handleChangeYearTrack}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {yearTrack.map((year) => (
                      <MenuItem key={year} value={year}>
                        <Checkbox checked={yearTrackFilter.indexOf(year) > -1} />
                        <ListItemText primary={year} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>


              </StyledTableRow>
            </TableHead>
          </Table>
        </TableContainer>


        <CircularProgress />
      </Paper>
    )
  }




  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 1500 }}>
        <Table stickyHeader>
          <TableHead aria-label="sticky table" className="thead-dark">
            <StyledTableRow>

              <TableCell align="center">
                <InputLabel variant="standard">comCode</InputLabel>
                <NativeSelect
                  name="comCode"
                  id="comCode"
                  defaultValue={comCodeFilter}
                  onChange={handleSelectComCode}
                >
                  <option value="All">All</option>
                  {comCodeFilterOptions}
                </NativeSelect>
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">comID</InputLabel>
                <NativeSelect
                  name="comID"
                  id="comID"
                  defaultValue={comIDFilter}
                  onChange={handleSelectComID}
                >
                  <option value="All">All</option>
                  {comIDFilterOptions}
                </NativeSelect>
              </TableCell>
              <TableCell align="center">
                <InputLabel variant="standard">Facility</InputLabel>
                <NativeSelect
                  name="facilityName"
                  id="facilityName"
                  defaultValue={facilityNameFilter}
                  onChange={handleSelectFacilityName}
                >
                  <option value="All">All</option>
                  {facilityNameFilterOptions}
                </NativeSelect>
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">ID</InputLabel>_
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">Name</InputLabel>_
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">Start</InputLabel>_
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">End</InputLabel>_
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="standard">PY Name</InputLabel>_
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="demo-multiple-checkbox-label">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={programTypeFilter}
                  onChange={handleChangeProgramType}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={

                    (selected) => {
                      selected.map((obj) => {

                        if (obj === 0) {
                          return "0".join(", ");
                        }
                        if (obj === 1) {
                          return "1".join(", ");
                        }
                        if (obj === 2) {
                          return "2".join(", ");
                        }

                      })
                    }
                  }

                >
                  {programType.map((program) => (
                    <MenuItem key={program.id} value={program.id}>
                      <Checkbox
                        checked={programTypeFilter.indexOf(program.id) > -1}
                      />
                      <ListItemText primary={program.value} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="demo-multiple-checkbox-label">
                  SeasonTrack
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={seasonTrackFilter}
                  onChange={handleChangeSeasonTrack}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.map((obj) => seasonTrack[obj - 1].value).join(", ")}

                >
                  {seasonTrack.map((season) => (
                    <MenuItem key={season.id} value={season.id}>
                      <Checkbox
                        checked={seasonTrackFilter.indexOf(season.id) > -1}
                      />
                      <ListItemText primary={season.value} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell align="center">
                <InputLabel variant="demo-multiple-checkbox-label">
                  YearTrack
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={yearTrackFilter}
                  onChange={handleChangeYearTrack}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {yearTrack.map((year) => (
                    <MenuItem key={year} value={year}>
                      <Checkbox checked={yearTrackFilter.indexOf(year) > -1} />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>


            </StyledTableRow>
          </TableHead>
          <TableBody>{newSeasonComponentArrayRefresh}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
