
import React, { useState, useEffect } from 'react';
import AuditList from './AuditList'
import { ExportJsonCsv } from 'react-export-json-csv';
import { Container, Row, Col, Form } from 'react-bootstrap';
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import LocationNotes from './LocationNotes';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT ?? 'http://localhost:7071/api';

export default function Audit(props) {

    const filename = "Csv-file" + new Date().toISOString().slice(0, 10);
    const headers = [
        { key: 'sKey', name: 'sKey' },
        { key: 'comCode', name: 'comCode' },
        { key: 'comID', name: 'comID' },
        { key: 'facilityName', name: 'facilityName' },
        { key: 'seasonID', name: 'seasonID' },
        { key: 'seasonName', name: 'seasonName' },
        { key: 'seasonDateS', name: 'seasonDateS' },
        { key: 'seasonDateE', name: 'seasonDateE' },
        { key: 'seasonDesc', name: 'seasonDesc' },
        { key: 'priorYearSeasonId', name: 'priorYearSeasonId' },
        { key: 'priorYearSeasonName', name: 'priorYearSeasonName' },
        { key: 'ProgramType', name: 'ProgramType' },
        { key: 'TrackingSeason', name: 'TrackingSeason' },
        { key: 'TrackingYear', name: 'TrackingYear' },
        { key: 'emailList', name: 'emailList' },
        { key: 'added', name: 'added' },
        { key: 'accepted', name: 'accepted' }
    ]

    const comcodes = props.comcodes;
    const handleUpdateLocationDimFacility = props.handleUpdateLocationDimFacility;
    const seasonDataForQueries = props.seasonDataForQueries;

    const [mergedData, setMergedData] = useState([]);
    const [baseData, setBaseData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [error, setError] = useState("");
    const [isInAsynch, setisInAsynch] = useState(false);

    const [dateStartSearchStartOfRange, setDateStartSearchStartOfRange] = useState(new dayjs().subtract(15, 'day'));
    const [dateStartSearchEndOfRange, setDateStartSearchEndOfRange] = useState(new dayjs().add(6, 'month'));

    // const seasonDataForQueries = mergedData;
    console.log("Rendering MainMaterial Component ***");

    const updateDateSearchLKSITableStartDate = (date) => {
        console.log("date:", date)
        changeSearchLKSITableStartDate(date);
    }


    const updateDateSearchSearchEndOfRange = (date) => {
        console.log("date:", date)
        changeStartSearchEndOfRange(date);
    }

    const changeSearchLKSITableStartDate = (date) => {
        console.log("In the app changeSearchLKSITableStartDate method: ", date);
        setDateStartSearchStartOfRange(date)

    }

    const changeStartSearchEndOfRange = (date) => {
        console.log("In the app changeStartSearchEndOfRange method: ", date);
        setDateStartSearchEndOfRange(date)
    }


    // let mergedDataCopy = mergedData;

    let mergedDataCopy = mergedData.map((mergedObj) => {

        const transformed = {
            ...mergedObj
        }

        return transformed;

    })

    const setBaseDBData = (dbData) => {
        setBaseData(dbData);
    }

    useEffect(() => {

        const tempfunction = async () => {

            const responseFromUpdateDatabase = await updateSeasonsFromDatabase();
            const responseFromUpdateDataFromWarehouse = await updateDataFromWarehouseQuery();
            const responseFromMergeLists = await mergeLists();
        }
        tempfunction();

        // updateInAsyncronousManner();

    }, []);

    // run this when the 'baseData' state variable has changed. Note that this is how we
    // are handling the asyncronous aspect of setting state.
    useEffect(() => {
        const tempfunction = async () => {
            // const responseFromUpdateDatabase = await updateSeasonsFromDatabase();
            // console.log("responseFromUpdateDatabase result: ", responseFromUpdateDatabase)

            const responseFromMergeLists = await mergeLists();
        }
        tempfunction();

    }, [newData, baseData]); //array is list of variables that will cause this to rerun if changed
    // only rerendering on newData because baseData is always also called and it's done first
    //with a wait



    const updateInAsyncronousManner = async () => {

        try {
            setisInAsynch(true);

            // // const foo = await updateSeasonsFromDatabase();
            // updateSeasonsFromDatabase();
            const responseFromUpdateDatabase = await updateSeasonsFromDatabase();
            console.log("responseFromUpdateDatabase result: ", responseFromUpdateDatabase)

            const responseFromWarehouseQuery = await updateDataFromWarehouseQuery();
            console.log("responseFromWarehouseQuery result: ", responseFromWarehouseQuery)
            // updateDataFromWarehouseQuery();
            const responseFromMergedList = await mergeListsDontSetState();
        }
        catch (e) {
            console.log("updateInAsynchronousManner just threw. Here's the error e: ", e)
        }
        finally {
            setisInAsynch(false)
        }
    }


    const updateSeasonsFromDatabase = async () => {

        return new Promise(async function (resolve, reject) {

            const localDate = dayjs.utc(dateStartSearchStartOfRange);
            const nextStepLocalDate = localDate.tz("America/Los_Angeles");
            const localDateString = nextStepLocalDate.toISOString().split('T');
            const justTheLocaldate = localDateString[0];
            console.log("justTheLocalDate", justTheLocaldate);

            const dateString = dateStartSearchStartOfRange.toISOString().split('T');
            const justTheDate = dateString[0];
            console.log("justTheDate", justTheDate);

            const endDateString = dateStartSearchEndOfRange.toISOString().split('T');
            const justTheEndDate = endDateString[0];
            console.log("justTheEndDate", justTheEndDate);

            let removeSpecialCharsSeasonName = "";
            let removeSpecialCharsFacilityName = "";

            console.log("updateSeasonsFromDatabase() within App")

            fetch(`${apiEndpoint}/savedseasons-daterange/${justTheDate}/${justTheEndDate}`)
                .then((response) => response.json())

                .then((actualData) => {
                    // throw new Error("Thrown from the fetch()");

                    const transformedData = actualData.data[0].map((seasonObj) => {
                        const dateSeasonStartStringS = seasonObj.seasonDateS;
                        const dateS = new Date(dateSeasonStartStringS);
                        const dateStringS = dateS.toISOString().split('T');
                        const justTheDateS = dateStringS[0];
                        const dateSeasonStartStringE = seasonObj.seasonDateE;
                        const dateE = new Date(dateSeasonStartStringE);
                        const dateStringE = dateE.toISOString().split('T');
                        const justTheDateE = dateStringE[0];
                        const seasonTrack = seasonObj.trackingSeason;
                        const yearTrack = seasonObj.trackingYear;
                        const comID = seasonObj.comID;

                        removeSpecialCharsSeasonName = seasonObj.seasonName.replace(/[^a-zA-Z0-9 ]/g, '');
                        if (seasonObj.facilityName) { removeSpecialCharsFacilityName = seasonObj.facilityName.replace(/[^a-zA-Z0-9 ]/g, ''); }

                        const transformed = {
                            ...seasonObj,
                            seasonDateS: justTheDateS,
                            seasonDateE: justTheDateE,
                            seasonName: removeSpecialCharsSeasonName,
                            facilityName: removeSpecialCharsFacilityName,
                            TrackingSeason: seasonTrack.toString(),
                            TrackingYear: yearTrack.toString(),
                            comID: comID.toString()
                        }

                        return transformed;

                    })
                    setBaseDBData(transformedData);
                    resolve('success')
                })
                .catch(function (e) {
                    console.log("updateSeasonsFromDatabase call failed with the following error e:  ", e);
                    reject('failure')
                })
        })
    }

    const updateDataFromWarehouseQuery = async () => {

        return new Promise(async function (resolve, reject) {
            setisInAsynch(true);

            const dateString = dateStartSearchStartOfRange.toISOString().split('T');
            const justTheDate = dateString[0];
            // console.log("dateString", dateString);
            // console.log("justTheDate", justTheDate);

            const endDateString = dateStartSearchEndOfRange.toISOString().split('T');
            const justTheEndDate = endDateString[0];
            // console.log("justTheEndDate", justTheEndDate);

            fetch(`${apiEndpoint}/new-season-pull/${justTheDate}/${justTheEndDate}`)

                .then((response) => response.json())
                .then((actualData) => {


                    const newDataWithAddedFields = actualData.data[0].map((seasonObj) => {

                        const dateSeasonStartStringS = seasonObj.seasonDateS;
                        const dateS = new Date(dateSeasonStartStringS);
                        const dateStringS = dateS.toISOString().split('T');
                        const justTheDateS = dateStringS[0];

                        const dateSeasonStartStringE = seasonObj.seasonDateE;
                        const dateE = new Date(dateSeasonStartStringE);
                        const dateStringE = dateE.toISOString().split('T');
                        const justTheDateE = dateStringE[0];

                        let seasonGuess = "no guess"
                        let trackingYearGuess = "2022"
                        let removeSpecialCharsSeasonName = "";
                        let removeSpecialCharsSeasonDesc = "";
                        let removeSpecialCharsPriorYearSeasonName = "";
                        let removeSpecialCharsFacilityName = "";

                        if (justTheDateS) {
                            switch (justTheDateS.split('-')[1]) {
                                case '12':
                                case '01':
                                case '02':
                                    seasonGuess = "4";
                                    break;
                                case '03':
                                case '04':
                                case '05':
                                    seasonGuess = "1";
                                    break;
                                case '06':
                                case '07':
                                case '08':
                                    seasonGuess = "2";
                                    break;
                                case '09':
                                case '10':
                                case '11':
                                    seasonGuess = "3";
                                    break;
                            }

                            trackingYearGuess = (justTheDateS.split('-')[0])

                            if (seasonObj.seasonName) { removeSpecialCharsSeasonName = seasonObj.seasonName.replace(/[^a-zA-Z0-9 ]/g, ''); }
                            if (seasonObj.seasonDesc) { removeSpecialCharsSeasonDesc = seasonObj.seasonDesc.replace(/[^a-zA-Z0-9 ]/g, ''); }
                            if (seasonObj.priorYearSeasonName) { removeSpecialCharsPriorYearSeasonName = seasonObj.priorYearSeasonName.replace(/[^a-zA-Z0-9 ]/g, ''); }
                            if (seasonObj.facilityName) { removeSpecialCharsFacilityName = seasonObj.facilityName.replace(/[^a-zA-Z0-9 ]/g, ''); }

                        }

                        // The transform will add the sKey, and it will remove the commas from the emailList replacing with semi colons
                        const newTransformed = {
                            ...seasonObj,
                            seasonDateS: justTheDateS,
                            seasonDateE: justTheDateE,
                            seasonName: removeSpecialCharsSeasonName,
                            seasonDesc: removeSpecialCharsSeasonDesc,
                            priorYearSeasonName: removeSpecialCharsPriorYearSeasonName,
                            facilityName: removeSpecialCharsFacilityName,
                            sKey: "0-" + seasonObj.comID + "-" + seasonObj.seasonID,
                            emailList: (seasonObj.emailList) ? seasonObj.emailList.replace(/,/g, ';') : "",
                            ProgramType: "0",
                            TrackingSeason: seasonGuess,
                            TrackingYear: trackingYearGuess,
                            added: (seasonObj.added === "FALSE") ? false : (seasonObj.added) ? true : false,
                            accepted: (seasonObj.accepted === "FALSE") ? false : (seasonObj.accepted) ? true : false,
                            comID: seasonObj.comID.toString()

                        }

                        return newTransformed;

                    })

                    setNewData(newDataWithAddedFields);
                    setisInAsynch(false);
                    resolve('success')
                })
                .catch((e) => {
                    console.log("updateDataFromWarehouseQuery call failed with the following error e:  ", e);
                    reject('failure')
                })
                .finally(() => {
                    setisInAsynch(false);
                })

        })
    }

    const mergeLists = async () => {

        return new Promise(async function (resolve, reject) {

            setisInAsynch(true);
            // console.log("baseData right before mapping: ", baseData);
            try {
                const mergedList = baseData.map((seasonObj) => {

                    const transformed = {
                        ...seasonObj
                    }

                    return transformed;

                })

                newData.forEach((seasonObj) => {
                    const index = baseData.findIndex(element => {
                        if (element.sKey === seasonObj.sKey) {
                            return true
                        }
                        return false;
                    })

                    if (index === -1) {

                        mergedList.push(seasonObj);
                    }

                    else {

                        if (seasonObj.facilityName !== mergedList[index].facilityName) {
                            const tempFacility = mergedList[index].facilityName;
                            mergedList[index].facilityName = seasonObj.facilityName;
                            mergedList[index].OrigFacilityName = tempFacility
                            mergedList[index].accepted = false
                        }
                        if (seasonObj.seasonName !== mergedList[index].seasonName) {
                            const tempSeasonName = mergedList[index].seasonName;
                            mergedList[index].seasonName = seasonObj.seasonName;
                            mergedList[index].OrigSeasonName = tempSeasonName;
                            mergedList[index].accepted = false
                        }
                        if (seasonObj.seasonDateS !== mergedList[index].seasonDateS) {
                            const tempDate = mergedList[index].seasonDateS;
                            mergedList[index].seasonDateS = seasonObj.seasonDateS
                            mergedList[index].OrigSeasonDateS = tempDate
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.seasonDateE !== mergedList[index].seasonDateE) {
                            const tempDate = mergedList[index].seasonDateE;
                            mergedList[index].seasonDateE = seasonObj.seasonDateE
                            mergedList[index].OrigSeasonDateE = tempDate
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.seasonDesc !== mergedList[index].seasonDesc) {
                            const tempSeasonDesc = mergedList[index].seasonDesc;
                            mergedList[index].seasonDesc = seasonObj.seasonDesc;
                            mergedList[index].OrigSeasonDesc = tempSeasonDesc
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.priorYearSeasonId !== mergedList[index].priorYearSeasonId) {
                            const tempPriorYearSeasonId = mergedList[index].priorYearSeasonId;
                            mergedList[index].priorYearSeasonId = seasonObj.priorYearSeasonId
                            mergedList[index].OrigPriorYearSeasonId = tempPriorYearSeasonId;
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.priorYearSeasonName !== mergedList[index].priorYearSeasonName) {
                            const tempPriorYearSeasonName = mergedList[index].priorYearSeasonName;
                            mergedList[index].priorYearSeasonName = seasonObj.priorYearSeasonName
                            mergedList[index].OrigPriorYearSeasonName = tempPriorYearSeasonName;
                            mergedList[index].accepted = false
                        }

                        mergedList[index].added = mergedList[index].added ? mergedList[index].added : false;
                        mergedList[index].accepted = mergedList[index].accepted ? mergedList[index].accepted : false;

                    }

                })

                const tempMergedList = mergedList.map((mergedObj) => {

                    const transformed = {
                        ...mergedObj
                    }

                    return transformed;

                })

                setMergedData(tempMergedList);
                setisInAsynch(false);
                resolve('success')

            } catch (error) {
                console.log("\n\n", error);
                setisInAsynch(false);
                reject('failure')
            }
            finally {
                setisInAsynch(false);
            }

        })
    };

    const mergeListsDontSetState = async () => {

        return new Promise(async function (resolve, reject) {

            setisInAsynch(true);
            console.log("baseData right before mapping: ", baseData);
            try {
                const mergedList = baseData.map((seasonObj) => {

                    const transformed = {
                        ...seasonObj
                    }

                    return transformed;

                })

                newData.forEach((seasonObj) => {
                    const index = baseData.findIndex(element => {
                        if (element.sKey === seasonObj.sKey) {
                            return true
                        }
                        return false;
                    })

                    if (index === -1) {

                        mergedList.push(seasonObj);
                    }

                    else {

                        if (seasonObj.facilityName !== mergedList[index].facilityName) {
                            const tempFacility = mergedList[index].facilityName;
                            mergedList[index].facilityName = seasonObj.facilityName;
                            mergedList[index].OrigFacilityName = tempFacility
                            mergedList[index].accepted = false
                        }
                        if (seasonObj.seasonName !== mergedList[index].seasonName) {
                            const tempSeasonName = mergedList[index].seasonName;
                            mergedList[index].seasonName = seasonObj.seasonName;
                            mergedList[index].OrigSeasonName = tempSeasonName;
                            mergedList[index].accepted = false
                        }
                        if (seasonObj.seasonDateS !== mergedList[index].seasonDateS) {
                            const tempDate = mergedList[index].seasonDateS;
                            mergedList[index].seasonDateS = seasonObj.seasonDateS
                            mergedList[index].OrigSeasonDateS = tempDate
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.seasonDateE !== mergedList[index].seasonDateE) {
                            const tempDate = mergedList[index].seasonDateE;
                            mergedList[index].seasonDateE = seasonObj.seasonDateE
                            mergedList[index].OrigSeasonDateE = tempDate
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.seasonDesc !== mergedList[index].seasonDesc) {
                            const tempSeasonDesc = mergedList[index].seasonDesc;
                            mergedList[index].seasonDesc = seasonObj.seasonDesc;
                            mergedList[index].OrigSeasonDesc = tempSeasonDesc
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.priorYearSeasonId !== mergedList[index].priorYearSeasonId) {
                            const tempPriorYearSeasonId = mergedList[index].priorYearSeasonId;
                            mergedList[index].priorYearSeasonId = seasonObj.priorYearSeasonId
                            mergedList[index].OrigPriorYearSeasonId = tempPriorYearSeasonId;
                            mergedList[index].accepted = false
                        }

                        if (seasonObj.priorYearSeasonName !== mergedList[index].priorYearSeasonName) {
                            const tempPriorYearSeasonName = mergedList[index].priorYearSeasonName;
                            mergedList[index].priorYearSeasonName = seasonObj.priorYearSeasonName
                            mergedList[index].OrigPriorYearSeasonName = tempPriorYearSeasonName;
                            mergedList[index].accepted = false
                        }

                        mergedList[index].added = mergedList[index].added ? mergedList[index].added : false;
                        mergedList[index].accepted = mergedList[index].accepted ? mergedList[index].accepted : false;

                    }

                })

                const tempMergedList = mergedList.map((mergedObj) => {

                    const transformed = {
                        ...mergedObj
                    }

                    return transformed;

                })

                mergedDataCopy = tempMergedList;

                // setMergedData(tempMergedList);
                setisInAsynch(false);
                resolve('success')

            } catch (error) {
                console.log("\n\n", error);
                setisInAsynch(false);
                reject('failure')
            }
            finally {
                setisInAsynch(false);
            }

        })
    };



    const handleDeleteFromRESTApi = async (sKey) => {

        return new Promise(async function (resolve, reject) {

            setisInAsynch(true);
            const requestOptions = {
                method: 'DELETE'
            };

            try {
                // const response = await fetch(`${apiEndpoint}/remove/${sKey}`, requestOptions);
                const response = await fetch(`${apiEndpoint}/savedseasons-remove/${sKey}`, requestOptions);
                const json = await response.json();
                const responseFromUpdateDatabase = await updateSeasonsFromDatabase();
                // updateSeasonsFromDatabase();
                setisInAsynch(false);
                resolve('success')


            }
            catch (e) {
                console.log("Throw in handleDeleteFromRESTAPI e: ", e);
                reject('failure')
            }
            finally {
                setisInAsynch(false);
            }

        })
    }

    const handleAddOrUpdateFromRestApi = async (seasonRowData) => {

        return new Promise(async function (resolve, reject) {
            setisInAsynch(true);

            console.log("handleAddOrUpdateFromRestApi called in Main Component");

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    sKey: seasonRowData.sKey,
                    com_code: seasonRowData.com_code,
                    comID: seasonRowData.comID,
                    facilityName: seasonRowData.facilityName,
                    seasonID: seasonRowData.seasonID,
                    seasonName: seasonRowData.seasonName,
                    seasonDateS: seasonRowData.seasonDateS,
                    seasonDateE: seasonRowData.seasonDateE,
                    seasonDesc: seasonRowData.seasonDesc,
                    priorYearSeasonId: seasonRowData.priorYearSeasonId,
                    priorYearSeasonName: seasonRowData.priorYearSeasonName,
                    programType: seasonRowData.programType,
                    emailList: seasonRowData.emailList,
                    trackingYear: seasonRowData.trackingYear,
                    trackingSeason: seasonRowData.trackingSeason,
                    added: seasonRowData.added,
                    accepted: true
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            };

            try {
                // const response = await fetch(`${apiEndpoint}/AddUpdate`, requestOptions)
                const response = await fetch(`${apiEndpoint}/savedseasons-add-update`, requestOptions)
                const json = await response.json();
                const responseFromUpdateDatabase = await updateSeasonsFromDatabase();
                console.log("responseFromUpdateDatabase result: ", responseFromUpdateDatabase)
                // updateSeasonsFromDatabase();
                setisInAsynch(false);
                resolve('success')

            }
            catch (e) {
                console.log("Throw in handleAddOrUpdateFromRestApi e: ", e)
                setisInAsynch(false);
                reject('failure')

            }
            finally {

                setisInAsynch(false);
            }
        })

    }


    const handleUpdateDates = () => {

        updateInAsyncronousManner();

    }


    return (

        <Container fluid>

            <Grid container alignItems="center" justify="center" >

                <Grid md={6} >
                    <p></p>
                    <item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Season Start Date - Start of Range"
                                value={dateStartSearchStartOfRange}
                                onChange={(date) => updateDateSearchLKSITableStartDate(date)}
                            />
                            <DatePicker
                                label="Season Start Date - End of Range"
                                value={dateStartSearchEndOfRange}
                                onChange={(date) => updateDateSearchSearchEndOfRange(date)}
                            />
                        </LocalizationProvider>
                    </item>
                    <item>  </item>
                    <item  >
                        <Button variant="contained" color="primary" onClick={e => handleUpdateDates(e)}>
                            Refresh Dates
                        </Button>
                    </item>
                </Grid>
                <Grid md={4}>
                    <p></p>
                    <item>
                        {isInAsynch ? <CircularProgress /> : <></>}
                    </item>
                </Grid>
                <Grid >
                    <p></p>
                    <item md="2" align="right" justify="right">
                        <ExportJsonCsv className='btn btn-primary' headers={headers} items={mergedData} fileTitle={filename}>DownLoad CSV</ExportJsonCsv>
                    </item>
                </Grid>

            </Grid>
            <hr></hr>
            <Grid>
                <Grid >

                    <AuditList seasonsArray={mergedDataCopy} comcodes={comcodes} seasonDataForQueries={seasonDataForQueries}
                        handleUpdateLocationDimFacility={handleUpdateLocationDimFacility}
                        deleteSqlRow={handleDeleteFromRESTApi} addOrUpdateSQLRow={handleAddOrUpdateFromRestApi}
                        updateInAsyncronousManner={updateInAsyncronousManner} />

                </Grid>

            </Grid>

        </Container>

    );

}