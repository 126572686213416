import * as React from "react";
import { useState, useEffect } from "react";
import CollapsibleTable from "./CollapsibleTable";
import Checkbox from "@mui/material/Checkbox";
const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT ?? "http://localhost:7071/api";

export default function ComCodes(props) {
  const comcodes = props.comcodes;
  const filteredComcodeData = props.filteredComcodeData;
  const updateComcodes = props.updateComcodes;
  const handleChangeFilter = props.handleChangeFilter;

  

  const handleUpdateLKFieldsDimFranchise = (franchiseRowData) => {
    console.log("in the handler for handleUpdateLKFieldsDimFranchise");
    console.log("franchiseRowData", franchiseRowData);

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        dim_franchiseID: franchiseRowData.dim_franchiseID,
        pacesetter: franchiseRowData.pacesetter,
        lkNotes: franchiseRowData.lkNotes,
        isLKSIActive: franchiseRowData.isLKSIActive,
        LKSIAccountRepName: franchiseRowData.LKSIAccountRepName,
        LKSIAccountRepEmail: franchiseRowData.LKSIAccountRepEmail
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    fetch(`${apiEndpoint}/franchise-update`, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(
          "actualDate.data returned from the update method",
          actualData.data
        );
        updateComcodes();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleUpdateLKFieldsDimFacility_Location = (facilityRowData) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        dim_facilityID: facilityRowData.dim_facilityID,
        locationLKSIEmail: facilityRowData.locationLKSIEmail,
        locationLKSINotes: facilityRowData.locationLKSINotes,
        isLKSIActive: facilityRowData.locationisLKSIActive,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    fetch(`${apiEndpoint}/facility-update`, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(
          "actualDate.data returned from the update method",
          actualData.data
        );

        updateComcodes();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleRemoveFacilitySite = (idfacility_site) => {
    console.log(
      "in the handler for handleRemoveFacilitySite:",
      idfacility_site
    );

    const requestOptions = {
      method: "DELETE",
    };

    fetch(
      `${apiEndpoint}/facilitysite-remove/${idfacility_site}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((actualData) => {
        console.log(
          "actualDate.data returned from the facilitysite-remove method",
          actualData.data
        );
        updateComcodes();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleAddUpdateFacilitySite = (facilitySiteRowData) => {
    console.log(
      "in the handler for handleAddUpdateFacilitySite:",
      facilitySiteRowData
    );

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        facility_site_id: facilitySiteRowData.facility_site_id,
        facilityName: facilitySiteRowData.facilityName,
        facility_numFields: facilitySiteRowData.facility_numFields,
        facilitySpace: facilitySiteRowData.facilitySpace,
        facilityIndoorAvail: facilitySiteRowData.facilityIndoorAvail,
        facilityOutdoorAvail: facilitySiteRowData.facilityOutdoorAvail,
        facility_Active: facilitySiteRowData.facility_Active,
        facility_source: facilitySiteRowData.facility_source,
        facility_id: facilitySiteRowData.facility_id,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    fetch(`${apiEndpoint}/facilitysite-add-update`, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(
          "actualDate.data returned from the update method",
          actualData.data
        );
        updateComcodes();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  

  if (comcodes.length === 0 )
  {
    console.log("no comcodes in comcodes.js: ", comcodes)
    console.log("filteredComcodeData comcodes.js: ", filteredComcodeData)
    return (
      <div> No Comcodes </div>
    )
  }
  else {
    console.log("there are comcodes in comcodes.js: ", comcodes)
    console.log("filteredComcodeData comcodes.js: ", filteredComcodeData)
  }

  const comcodesArray = comcodes.map((comcodeObj) => {
    const element = (
      <CollapsibleTable
        comcode={comcodeObj}
        key={comcodeObj.comID + comcodeObj.franchise_name}
        handleUpdateLKFieldsDimFranchise={handleUpdateLKFieldsDimFranchise}
        handleUpdateLKFieldsDimFacility_Location={
          handleUpdateLKFieldsDimFacility_Location
        }
        handleAddUpdateFacilitySite={handleAddUpdateFacilitySite}
        handleRemoveFacilitySite={handleRemoveFacilitySite} 
      ></CollapsibleTable>
    );

    return element;
  });


  return (
    <div>
      Show Active Only:
      <Checkbox
      defaultChecked
        onChange={() => handleChangeFilter()}
      ></Checkbox>
      <div className="collapsibleTable">{comcodesArray}</div>

    </div>
  );
}
