import React, { useState, useEffect } from "react";
import SeasonList from "./SeasonList";
import { ExportJsonCsv } from "react-export-json-csv";
import { Container, Row, Col, Form } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";

import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import LocationNotes from "./LocationNotes";

// import sampleSeasonData from "../components/sampleseasons.json";

const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT ?? "http://localhost:7071/api";


export default function Season(props) {
  const filename = "Csv-file" + new Date().toISOString().slice(0, 10);
  const headers = [
    { key: "sKey", name: "sKey" },
    { key: "comCode", name: "comCode" },
    { key: "comID", name: "comID" },
    { key: "facilityName", name: "facilityName" },
    { key: "seasonID", name: "seasonID" },
    { key: "seasonName", name: "seasonName" },
    { key: "seasonDateS", name: "seasonDateS" },
    { key: "seasonDateE", name: "seasonDateE" },
    { key: "seasonDesc", name: "seasonDesc" },
    { key: "priorYearSeasonId", name: "priorYearSeasonId" },
    { key: "priorYearSeasonName", name: "priorYearSeasonName" },
    { key: "ProgramType", name: "ProgramType" },
    { key: "TrackingSeason", name: "TrackingSeason" },
    { key: "TrackingYear", name: "TrackingYear" },
    { key: "emailList", name: "emailList" },
    { key: "added", name: "added" },
    { key: "accepted", name: "accepted" },
  ];

  const comcodes = props.comcodes;

  const [seasonsArray, setSeasonsArray] = useState([]);
  const [isInAsynch, setisInAsynch] = useState(false);
  const [seasonTrackFilter, setSeasonTrackFilter] = useState(["3"]);
  const [yearTrackFilter, setYearTrackFilter] = useState(["2023"]);
  const [lkCount, setLKCount] = useState(0);
  const [siCount, setSICount] = useState(0);

  const seasonDataForQueries = props.seasonDataForQueries;

  function filterBy(seasonObj) {
    if (
      (seasonTrackFilter.length !== 0 && !seasonTrackFilter.includes(seasonObj.TrackingSeason)) ||
      (yearTrackFilter.length !== 0 && !yearTrackFilter.includes(seasonObj.TrackingYear))
    ) {

      return false;

    } else {

      return true;
    }
  }

  // const filteredSeasonsArray = seasonsArray.filter(filterBy);

  useEffect(() => {


    const tempfunction = async () => {
      const filteredSeasonsArray = seasonDataForQueries.filter(filterBy);
      console.log("filteredSeasonsArray: ", filteredSeasonsArray)
      setSeasonsArray(filteredSeasonsArray)

    }



    tempfunction();


  }, []);

  const handleChangeSeasonTrack = (event) => {
    const {
      target: { value },
    } = event;
    setSeasonTrackFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeYearTrack = (event) => {
    const {
      target: { value },
    } = event;
    setYearTrackFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };



  const handleUpdateDates = () => {

      const filteredSeasonsArray = seasonDataForQueries.filter(filterBy);
      console.log("filteredSeasonsArray: ", filteredSeasonsArray)
      setSeasonsArray(filteredSeasonsArray)

  }

  const handleLKCountUpdate = (count) => {
    setLKCount(count);
  };

  const handleSICountUpdate = (count) => {
    setSICount(count);
  };

  const seasonTrack = [
    {
      id: "1",
      value: "Spring"
    },
    {
      id: "2",
      value: "Summer"
    },
    {
      id: "3",
      value: "Fall"
    },
    {
      id: "4",
      value: "Winter"
    }
  ];


  const yearTrack = ["2023", "2022", "2021", "2020", "2019", "2018", "2017"];


  return (
    <Container fluid>
      <Grid container alignItems="center" justify="center" >
        <Grid md={6}>
          <p></p>
          <item>

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={seasonTrackFilter}
              onChange={handleChangeSeasonTrack}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.map((obj) => seasonTrack[obj - 1].value).join(", ")}

            >
              {seasonTrack.map((season) => (
                <MenuItem key={season.id} value={season.id}>
                  <Checkbox
                    checked={seasonTrackFilter.indexOf(season.id) > -1}
                  />
                  <ListItemText primary={season.value} />
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={yearTrackFilter}
              onChange={handleChangeYearTrack}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {yearTrack.map((year) => (
                <MenuItem key={year} value={year}>
                  <Checkbox checked={yearTrackFilter.indexOf(year) > -1} />
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>


          </item>
          <item>  </item>
          <item  >
            <Button variant="contained" color="primary" onClick={e => handleUpdateDates(e)}>
              Refresh 
            </Button>
          </item>

        </Grid>

        <Grid md={2}>
          <p></p>
          <item>
            {isInAsynch ? <CircularProgress /> : <></>}
          </item>
        </Grid>

        <Grid md={1}>
          <p className="count"><b>LK: {lkCount}</b></p>
        </Grid>
        <Grid md={1}>
          <p className="count"><b>SI: {siCount}</b></p>
        </Grid>

        <Grid >
          <p></p>
          <item md="2" align="right" justify="right">
            <ExportJsonCsv className='btn btn-primary' headers={headers} items={seasonsArray} fileTitle={filename}>DownLoad CSV</ExportJsonCsv>
          </item>
        </Grid>
      </Grid>
      <hr></hr>

      <Grid>
        <Grid>

          <SeasonList seasonsArray={seasonsArray} comcodes={comcodes} seasonDataForQueries={seasonDataForQueries} handleLKCountUpdate={handleLKCountUpdate} handleSICountUpdate={handleSICountUpdate}
          />

        </Grid >
      </Grid>

    </Container>
  );
}
