import React, { useState } from 'react';
import { Modal, Collapse } from "react-bootstrap";
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import { TextField } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
// import Collapse from '@mui/material/Collapse';


export default function AuditRow(props) {

  const { sKey, comCode, comID, facilityName, seasonID, seasonName, seasonDateS, seasonDateE, seasonDesc, priorYearSeasonId, priorYearSeasonName, ProgramType,
    TrackingSeason, TrackingYear, added, accepted, OrigFacilityName, OrigPriorYearSeasonId, OrigPriorYearSeasonName, OrigSeasonDateE, OrigSeasonDateS,
    OrigSeasonDesc, OrigSeasonName, OrigAdded, OrigAccepted } = props.seasonData; //destructuring!

  const dim_facilityID = props.dim_facilityID;
  // const seasonDataForQueries = props.seasonDataForQueries;

  const deleteSqlRow = props.deleteSqlRow;
  const addOrUpdateSQLRow = props.addOrUpdateSQLRow;

  const handleUpdateLocationDimFacility = props.handleUpdateLocationDimFacility;

  const [isToggled, setToggle] = useState(false);

  const [showDescription, setShowDescription] = useState(false);
  const handleShowDescription = () => setShowDescription(true);
  const handleCloseDescription = () => setShowDescription(false);

  const [showLocationNotes, setShowLocationNotes] = useState(false);
  const handleCloseLocationNotes = () => setShowLocationNotes(false);
  const handleShowLocationNotes = () => setShowLocationNotes(true);

  const handleLocationNotesChanged = (event) => {
    const newNotes = event.target.value;
    // console.log("newNotes", newNotes)
    setLocationNotes(newNotes);
  };

  const [showPriorSeasons, setShowPriorSeasons] = useState(false);
  const handleClosePriorSeasons = () => setShowPriorSeasons(false);
  const handleShowPriorSeasons = () => setShowPriorSeasons(true);


  const [checkedAdded, setCheckedAdded] = useState(added);
  const [checkedAccepted, setCheckedAccepted] = useState(accepted);
  const [ProgramTypeControl, setProgramTypeControl] = useState(props.seasonData.ProgramType);
  const [TrackingSeasonControl, setTrackingSeasonControl] = useState(props.seasonData.TrackingSeason);
  const [TrackingYearControl, setTrackingYearControl] = useState(props.seasonData.TrackingYear);

  const [locationNotes, setLocationNotes] = useState(props.locationNotes);
  const [priorSeasonArray, setPriorSeasonArray] = useState(props.priorSeasonArray);

  const handleUpdateLocationNotes = (e) => {
    // console.log("clicked update location notes")
    const locationRowData = {
      dim_facilityID: dim_facilityID,
      locationLKSINotes: locationNotes
    };
    handleUpdateLocationDimFacility(locationRowData)
  }


  const handleDeleteRow = async (sKey) => {

    console.log("handleDeleteRow called in SeasonRowComponent");

    const deletionpromise = await deleteSqlRow(sKey);
    console.log("deletion promise returned: ", deletionpromise);

    setCheckedAccepted(false);

  };


  const handleAddOrUpdateRow = async (sKey) => {

    console.log("handleAddOrUpdateRow() in SeasonRowComponent")

    const addOrUpdatePromise = await addOrUpdateSQLRow(sKey);
    console.log("update promise returned: ", addOrUpdatePromise)


    setCheckedAccepted(true);

  };


  const handleAddedChange = (event, sKey) => {

    console.log("handleAddedChange event :", event.target.checked);

    const newSetting = event.target.checked;

    setCheckedAdded(newSetting);
    setCheckedAccepted(false)

  };

  const handleProgramTypeChange = (event, sKey) => {

    const newSetting = ((event.target.value) ? event.target.value : 0)
    console.log("program change value from change: ", event.target.value)
    console.log(newSetting)
    setProgramTypeControl(newSetting);

    setCheckedAccepted(false)

  };

  const handleTrackingSeasonChange = (event, sKey) => {

    const newSetting = event.target.value
    setTrackingSeasonControl(newSetting);

    setCheckedAccepted(false)

  };

  const handleTrackingYearChange = (event, sKey) => {

    const newSetting = event.target.value
    setTrackingYearControl(newSetting);

    setCheckedAccepted(false)

  };

  const handleSeasonIDClicked = (event) => {


    const query = "Season/edit/" + seasonID + "/";
    const tabValue = "settings";

    const url = new URL('https://apps.daysmartrecreation.com/dash/admin');

    url.searchParams.set('Action', query);
    url.searchParams.set('tab', tabValue);

    const urlString = url.toString();


    console.log("urlString: ", urlString)

    window.open(urlString);

  };

  const priorSeasons = priorSeasonArray.map((seasonObj) => {

    const transformed =
    {
      TrackingSeason: seasonObj.TrackingSeason,
      TrackingYear: seasonObj.TrackingYear,
      comCode: seasonObj.comCode,
      seasonName: seasonObj.seasonName,
      seasonID: seasonObj.seasonID,
      facilityName: seasonObj.facilityName,
      seasonDateS: seasonObj.seasonDateS,
      seasonDateE: seasonObj.seasonDateE,
      comCode: seasonObj.comCode
    }


    return transformed;
  })

  // console.log("priorSeasons array!!!!", priorSeasons);

  const priorSeasonHtml = priorSeasons.map((pseasonObj) => {

    const pseason = {
      seasonID: pseasonObj.seasonID,
      seasonName: pseasonObj.seasonName,
      TrackingSeason: pseasonObj.TrackingSeason,
      TrackingYear: pseasonObj.TrackingYear,
      comCode: pseasonObj.comCode,
      seasonDateS: pseasonObj.seasonDateS,
      seasonDateE: pseasonObj.seasonDateE,
      facilityName: pseasonObj.facilityName
    }

    const transformed = (
      <PriorSeasonsTextArea key={pseason.seasonID + pseason.seasonName} pseason={pseason} />
    )
    
    // console.log("transformed within priorSeasonHTML", pseason);
    return transformed;

  })


  const seasonRowData = {
    sKey: sKey,
    com_code: comCode,
    comID: comID,
    facilityName: facilityName,
    seasonID: seasonID,
    seasonName: seasonName,
    seasonDateS: seasonDateS,
    seasonDateE: seasonDateE,
    seasonDesc: seasonDesc,
    priorYearSeasonId: priorYearSeasonId,
    priorYearSeasonName: priorYearSeasonName,
    programType: ProgramTypeControl,
    trackingYear: TrackingYearControl,
    trackingSeason: TrackingSeasonControl,
    added: checkedAdded,
    accepted: checkedAccepted
  }

// console.log("priorSeasonHtml", priorSeasonHtml)

  return (
    <>
      {/* <TableRow onClick={() => setToggle(!isToggled)}> */}
      <TableRow >
        {/* <StyledTableCell align="center" >{sKey}</StyledTableCell> */}

        <StyledTableCell align="center" >
          <Button variant="Link" onClick={handleShowPriorSeasons}>
            {sKey}
          </Button>
          <Modal show={showPriorSeasons} onHide={handleClosePriorSeasons}>
            <Modal.Header closeButton>
              <Modal.Title> {sKey} Possible Prior Seasons </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {priorSeasonHtml}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClosePriorSeasons}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>


        </StyledTableCell>

        <StyledTableCell align="center" >{comCode}</StyledTableCell>
        <StyledTableCell align="center"> {comID} </StyledTableCell>
        {/* <StyledTableCell align="center" sx={OrigFacilityName ? { fontWeight: 'bold' } : ''}> {facilityName}</StyledTableCell> */}

        <StyledTableCell align="center" sx={OrigFacilityName ? { fontWeight: 'bold' } : ''}>
          <Button variant="Link" onClick={handleShowLocationNotes}>
            {facilityName}
          </Button>
          <Modal show={showLocationNotes} onHide={handleCloseLocationNotes}>
            <Modal.Header closeButton>
              <Modal.Title> {facilityName} Notes </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LocationNotesTextArea
                defaultValue={locationNotes}
                onChange={handleLocationNotesChanged}>

              </LocationNotesTextArea>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleUpdateLocationNotes}>
                Update
              </Button>
              <Button variant="secondary" onClick={handleCloseLocationNotes}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>

        </StyledTableCell>

        <StyledTableCell align="center" onClick={handleSeasonIDClicked} >{seasonID}</StyledTableCell>
        <StyledTableCell align="center" onClick={() => setToggle(!isToggled)} sx={OrigSeasonName != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : ''}>{seasonName}</StyledTableCell>
        <StyledTableCell align="center" onClick={() => setToggle(!isToggled)} sx={OrigSeasonDateS != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : ''}>{seasonDateS}</StyledTableCell>
        <StyledTableCell align="center" onClick={() => setToggle(!isToggled)} sx={OrigSeasonDateE != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : 'small'}>{seasonDateE}</StyledTableCell>
        <StyledTableCell className={OrigSeasonDesc ? 'fixedNoWrap' : 'fixedNoWrap'} sx={OrigSeasonDesc != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : ''} onClick={() => setToggle(!isToggled)}>
          <Button variant="Link" onClick={handleShowDescription} >
            {seasonDesc}
          </Button>
          <Modal show={showDescription} onHide={handleCloseDescription}>
            <Modal.Header closeButton>
              <Modal.Title>Season Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>{seasonDesc}</Modal.Body>
            <Modal.Body>{OrigSeasonDesc}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDescription}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* {seasonDesc} */}
        </StyledTableCell>
        <StyledTableCell align="center" onClick={() => setToggle(!isToggled)} sx={OrigPriorYearSeasonId != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : ''}>{priorYearSeasonId}</StyledTableCell>
        <StyledTableCell align="center" onClick={() => setToggle(!isToggled)} sx={OrigPriorYearSeasonName != undefined ? { borderColor: 'error.main', borderRadius: '16px', fontWeight: 'bold' } : ''}>{priorYearSeasonName}</StyledTableCell>
        <StyledTableCell align="center">

          <NativeSelect
            name="ProgramType"
            id="ProgramType"
            defaultValue={ProgramTypeControl}
            onChange={e => handleProgramTypeChange(e, { sKey })}>
            <option value="1">LK - 1</option>
            <option value="2" >SI - 2</option>
            <option value="0">Other</option>
          </NativeSelect>

        </StyledTableCell>

        <StyledTableCell align="center">

          <NativeSelect
            name="TrackingSeason"
            id="TrackingSeason"
            defaultValue={TrackingSeasonControl}
            onChange={e => handleTrackingSeasonChange(e, { sKey })}>
            <option value="1">Spring</option>
            <option value="2">Summer</option>
            <option value="3">Fall</option>
            <option value="4">Winter</option>
            <option value="0">Other</option>
          </NativeSelect>

        </StyledTableCell>
        <StyledTableCell align="center">

          <NativeSelect
            name="TrackingYear"
            id="TrackingYear"
            defaultValue={TrackingYearControl}
            onChange={e => handleTrackingYearChange(e, { sKey })}>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
          </NativeSelect>

        </StyledTableCell>


        <StyledTableCell align="center" padding="checkbox">
          <Checkbox value={checkedAdded == 1} checked={checkedAdded == 1} onChange={e => handleAddedChange(e, { sKey })}></Checkbox>
        </StyledTableCell>

        <StyledTableCell align="center" >{checkedAccepted ? "Yes" : ""}</StyledTableCell>
        <StyledTableCell align="center">
          {checkedAccepted ? <Button variant="outlined" onClick={() => handleDeleteRow(sKey)}> Delete</Button> : <Button variant="contained"
            onClick={() => handleAddOrUpdateRow(seasonRowData)}>Add/Update</Button>}
        </StyledTableCell>


      </TableRow>
      <Collapse in={isToggled}>
        <TableRow >
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center"> { } </StyledTableCell>
          <StyledTableCell align="center">{OrigFacilityName}</StyledTableCell>
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center">{OrigSeasonName}</StyledTableCell>
          <StyledTableCell align="center">{OrigSeasonDateS}</StyledTableCell>
          <StyledTableCell align="center">{OrigSeasonDateE}</StyledTableCell>
          <StyledTableCell align="center">{OrigSeasonDesc}</StyledTableCell>
          <StyledTableCell align="center">{OrigPriorYearSeasonId}</StyledTableCell>
          <StyledTableCell align="center">{OrigPriorYearSeasonName}</StyledTableCell>
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center">{ }</StyledTableCell>
          <StyledTableCell align="center">{OrigAdded}</StyledTableCell>
          <StyledTableCell align="center">{OrigAccepted}</StyledTableCell>
        </TableRow>
      </Collapse>

    </>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StyledTextArea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 90%;`
);

export function LocationNotesTextArea(props) {
  return (
    <StyledTextArea
      defaultValue={props.defaultValue}
      onChange={props.onChange}
    ></StyledTextArea>
  );
}

export function PriorSeasonsTextArea(props) {

  const pseason = props.pseason;

  let seasonString = "";
  if (pseason.TrackingSeason === '1') 
  seasonString = "Spring";
  else if (pseason.TrackingSeason === '2')
  seasonString = "Summer"
  else if (pseason.TrackingSeason === '3')
  seasonString = "Fall"
  else if (pseason.TrackingSeason === '4')
  seasonString = "Winter"
  

  return (
    <div key={pseason.seasonID}>
      <h6>Name: {pseason.seasonName}</h6>
      <p>Season: {seasonString}</p>
      <p>SeasonID: {pseason.seasonID}</p>
      <p>Year: {pseason.TrackingYear}</p>
      <p>ComCode: {pseason.comCode}</p>
      <p>Start: {pseason.seasonDateS}</p>
      <p>End: {pseason.seasonDateE}</p>
      <p>Location: {pseason.facilityName}</p>
      <p>---------------</p>
    </div>
  );
}

