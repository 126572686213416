import React, { useEffect, useState } from "react";

import HeaderBar from "./HeaderBar";
import ProfilePage from "./ProfilePage";
import SignInPage from "./SignInPage";
import * as Static from "./StaticPages";
import Seasons from "./Seasons";
import Audit from "./Audit";
import ComCodes from "./ComCodes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import CircularProgress from "@mui/material/CircularProgress";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import comcodeDataCsv from "../components/comcodes.json";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT ?? "http://localhost:7071/api";

// Allowed extensions for input file
// const allowedExtensions = ["csv"];
function App(props) {
  const nullUser = { uid: null, displayName: null };
  const [currentUser, setCurrentUser] = useState(nullUser);
  const [comcodeData, setComCodeData] = useState([]);
  const [filteredComcodeData, setFilteredComcodeData] = useState([]);
  const [showActive, setShowActive] = useState(1);
  const [seasonDataForQueries, setSeasonDataForQueries] = useState([]);
  const [isInAsynch, setisInAsynch] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        //is defined, so "logged in"
        // console.log("authentication state changed");
        // console.log(firebaseUser);
        //add in the keys for the terms we want to use
        firebaseUser.userId = firebaseUser.uid;
        firebaseUser.userName = firebaseUser.displayName;
        setCurrentUser(firebaseUser);
      } else {
        //not defined, so logged out
        setCurrentUser(nullUser);
      }
    });
  }, []); //array is list of variables that will cause this to rerun if changed

  const loginUser = (userObject) => {
    //can do more checking here if we want
    setCurrentUser(userObject);
  };


  //effects to run when the component first loads
  useEffect(() => {

    const tempfunction = async () => {
      updateComcodes();
      const responseFromUpdateSeasonData = await updateSeasonDatesForQueries();

    }

    tempfunction();

  }, []); //array is list of variables that will cause this to rerun if changed

  //effects to run when the component first loads
  useEffect(() => {
    filterComcodes();

  }, [showActive]); //array is list of variables that will cause this to rerun if changed

  // filterComcodes();

  const handleUpdateLocationDimFacility = (facilityLocationData) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        dim_facilityID: facilityLocationData.dim_facilityID,
        locationLKSINotes: facilityLocationData.locationLKSINotes,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    fetch(`${apiEndpoint}/facility-notes`, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(
          "actualDate.data returned from the update method",
          actualData.data
        );

        updateComcodes();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const filterComcodes = () => {

    console.log("showActive: ", showActive);
    console.log("comcodeData in App.js in filterComCodes: ", comcodeData);

    const filteredActive = comcodeData.filter((franchise) => {
      if (showActive == 1) {
        console.log("franchise.isActive", franchise.isActive)
        if (franchise.isActive !== showActive) { //comparing to state instead of !== 1

          return false; // Exclude the franchise if isActive is not 1
        }

        franchise.locations = franchise.locations.filter((location) => {
          if (location.locationactive !== showActive) {
            return false; // Exclude the location if locationactive is not 1
          }
          location.facilities = location.facilities.filter((facility) => {
            return facility.facilityactive === showActive; // Include the facility if facilityactive is 1
          });

          return location.facilities.length > 0; // Keep the location if it has at least one active facility

        });

        return franchise.locations.length > 0; // Keep the franchise if it has at least one active location

      }

      return true;
    });

    if (showActive == 1) {
      setFilteredComcodeData(filteredActive);
      
    }
    else {
      // setFilteredComcodeData(comcodeData);
      updateComcodes();
    }
  };

  const updateComcodes = () => {
    setisInAsynch(true);
    fetch(`${apiEndpoint}/comcodes/`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log("actualData", actualData)

        let testFilterComcodes = actualData.transformed.map((comCodeObj) => {
          const transformed = {
            ...comCodeObj,
          };
          return transformed;
        });
        console.log("showActive: ", showActive);
        console.log("testFilterComcodes: ", testFilterComcodes);

        const filteredActive = testFilterComcodes.filter((franchise) => {
          if (showActive == 1) {
            if (franchise.isActive !== showActive) { //comparing to state instead of !== 1
              return false; // Exclude the franchise if isActive is not 1
            }

            franchise.locations = franchise.locations.filter((location) => {
              if (location.locationactive !== showActive) {
                return false; // Exclude the location if locationactive is not 1
              }
              location.facilities = location.facilities.filter((facility) => {
                return facility.facilityactive === showActive; // Include the facility if facilityactive is 1
              });

              return location.facilities.length > 0; // Keep the location if it has at least one active facility

            });

            return franchise.locations.length > 0; // Keep the franchise if it has at least one active location

            // }else {
            // return franchise;
          }
          return true;
        });
        setComCodeData(testFilterComcodes);
        setFilteredComcodeData(filteredActive);
        // console.log("FilterComcodes: ", filteredActive);
        console.log("comcodeData State: ", comcodeData);

        // console.log("comCode Data!!!", actualData.transformed);
        // console.log("testing");
        // setComCodeData(actualData.transformed);
        setisInAsynch(false);
      })

      .catch((err) => {
        console.log(err.message);
        console.log("comCodeDataCsv!!", comcodeDataCsv);
        setComCodeData(comcodeDataCsv);
        setisInAsynch(false);
      });
  };

  const handleChangeFilter = () => {

    if (showActive == 0) {

      setShowActive(1);
    } else {
      setShowActive(0);

    }
    console.log("showActive: ", showActive)

  };

  const updateSeasonDatesForQueries = async () => {
    return new Promise(async function (resolve, reject) {
      const startDateRange = new Date("2017-01-01");
      const startDateRangeConvert = startDateRange.toISOString().split("T");
      const startDateRangeString = startDateRangeConvert[0];
      // console.log("startDateRangeString", startDateRangeString);

      let endDateRange = new Date();
      endDateRange.setDate(endDateRange.getDate() + 100);
      const endDateRangeConvert = endDateRange.toISOString().split("T");
      const endDateRangeString = endDateRangeConvert[0];

      // console.log("endDateRangeString: ", endDateRangeString);

      let removeSpecialCharsSeasonName = "";
      let removeSpecialCharsFacilityName = "";

      // console.log("updateSeasonDatesForQueries() within App");

      fetch(
        `${apiEndpoint}/savedseasons-daterange/${startDateRangeString}/${endDateRangeString}`
      )
        .then((response) => response.json())

        .then((actualData) => {
          // throw new Error("Thrown from the fetch()");

          const transformedData = actualData.data[0].map((seasonObj) => {
            const dateSeasonStartStringS = seasonObj.seasonDateS;
            const dateS = new Date(dateSeasonStartStringS);
            const dateStringS = dateS.toISOString().split("T");
            const justTheDateS = dateStringS[0];
            const dateSeasonStartStringE = seasonObj.seasonDateE;
            const dateE = new Date(dateSeasonStartStringE);
            const dateStringE = dateE.toISOString().split("T");
            const justTheDateE = dateStringE[0];
            const seasonTrack = seasonObj.trackingSeason;
            const yearTrack = seasonObj.trackingYear;
            const comID = seasonObj.comID;

            removeSpecialCharsSeasonName = seasonObj.seasonName.replace(
              /[^a-zA-Z0-9 ]/g,
              ""
            );
            if (seasonObj.facilityName) {
              removeSpecialCharsFacilityName = seasonObj.facilityName.replace(
                /[^a-zA-Z0-9 ]/g,
                ""
              );
            }

            const transformed = {
              ...seasonObj,
              seasonDateS: justTheDateS,
              seasonDateE: justTheDateE,
              seasonName: removeSpecialCharsSeasonName,
              facilityName: removeSpecialCharsFacilityName,
              TrackingSeason: seasonTrack.toString(),
              TrackingYear: yearTrack.toString(),
              comID: comID.toString(),
            };

            return transformed;
          });
          setSeasonDataForQueries(transformedData);
          resolve("success");
        })
        .catch(function (e) {
          console.log(
            "updateSeasonDatesForQueries call failed with the following error e:  ",
            e
          );
          reject("failure");
        });
    });
  };


  return (
    <div className="container-fluid d-flex flex-column">
      <Routes>
        <Route
          element={
            <AppLayout currentUser={currentUser} loginUser={loginUser} />
          }
        >
          {/* protected routes */}
          <Route element={<ProtectedPage currentUser={currentUser} />}>
            <Route
              path="seasons"
              element={
                <Seasons
                  currentUser={currentUser}
                  comcodes={comcodeData}
                  seasonDataForQueries={seasonDataForQueries}
                />
              }
            />
            <Route
              path="profile"
              element={<ProfilePage currentUser={currentUser} />}
            />

            <Route
              path="audit"
              element={
                <Audit
                  currentUser={currentUser}
                  comcodes={comcodeData}
                  seasonDataForQueries={seasonDataForQueries}
                  handleUpdateLocationDimFacility={
                    handleUpdateLocationDimFacility
                  }
                  updateComcodes={updateComcodes}
                />
              }
            />

            <Route
              path="profile"
              element={<ProfilePage currentUser={currentUser} />}
            />
          </Route>

          {/* public routes */}
          <Route
            path="signin"
            element={
              <SignInPage currentUser={currentUser} loginFunction={loginUser} />
            }
          />
          {/* index is shortcut for path="/" */}
          <Route path="about" element={<Static.AboutPage />} />
          <Route
            path="comcodes"
            // element={
            //   (!showActive) ? <ComCodes
            //     comcodes={comcodeData}
            //     updateComcodes={updateComcodes}
            //     showActive={showActive}
            //     handleChangeFilter={handleChangeFilter}
            //   /> : <ComCodes
            //     comcodes={filteredComcodeData}
            //     updateComcodes={updateComcodes}
            //     showActive={showActive}
            //     handleChangeFilter={handleChangeFilter}
            //   />
            // }
            element={
              <ComCodes
                comcodes={filteredComcodeData}
                updateComcodes={updateComcodes}
                filteredComcodeData={filteredComcodeData}
                // showActive={showActive}
                handleChangeFilter={handleChangeFilter}
              />
            }

          />
          <Route path="*" element={<Static.ErrorPage />} />
        </Route>

        <Route
          index
          element={<Static.WelcomePage currentUser={currentUser} />}
        />
      </Routes>
    </div>
  );
}

function AppLayout({ currentUser, loginUser }) {
  return (
    <>
      <HeaderBar currentUser={currentUser} loginFunction={loginUser} />
      {/* {isInAsynch ? <CircularProgress></CircularProgress> : <>Hey there</>} */}
      {/* {isInAsynch ? <>Hey there</> : <CircularProgress></CircularProgress> } */}
      {/* the nested route */}
      <Outlet />
    </>
  );
}

function ProtectedPage(props) {
  //...determine if user is logged in
  // console.log(props.currentUser);
  if (!props.currentUser.uid) {
    //if no user, send to sign in
    return <Navigate to="/signin" />;
  } else {
    //otherwise, show the child route content
    return <Outlet />;
  }
}
export default App;
