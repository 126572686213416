import { NavLink } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

export default function HeaderBar(props) {
    const currentUser = props.currentUser.uid;

    const handleSignOut = (event) => {
        console.log("signing out");
        signOut(getAuth());

    }


    return (
        <header className="text-light bg-primary px-1 d-flex justify-content-between">
            <h1>LK-SI Season Manager</h1>

            {/* links go here */}
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/seasons">Seasons</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/audit">Audit</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/comcodes">ComCodes</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className="nav-link" to="/about">About</NavLink>
                </li>
                {!currentUser &&
                    <li className="nav-item">
                        <NavLink to="/signin" className="nav-link">Sign In</NavLink>
                    </li>
                }
                {currentUser && <>
                    <li className="nav-item">
                        <NavLink to="/profile" className="nav-link">Profile</NavLink>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-secondary ms-2" onClick={handleSignOut}>Sign Out</button>
                    </li>
                </>
                }
            </ul>
        </header>
    )

}