// import * as React from 'react';
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LocationRow from "./LocationRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

export default function CollapsibleTable(props) {
  const handleUpdateLKFieldsDimFranchise =
    props.handleUpdateLKFieldsDimFranchise;
  const handleUpdateLKFieldsDimFacility_Location =
    props.handleUpdateLKFieldsDimFacility_Location;
  const handleAddUpdateFacilitySite = props.handleAddUpdateFacilitySite;
  const handleRemoveFacilitySite = props.handleRemoveFacilitySite;

  const comID = props.comcode.comID;
  const comcode = props.comcode.comcode;
  const comcodeUpperCase = comcode.toUpperCase();
  const locations = props.comcode.locations;
  const isActive = props.comcode.isActive;
  const dim_franchiseID = props.comcode.dim_franchiseID;
  const facilityID = props.comcode.facilityID;

  const [showFranchiseNotes, setShowFranchiseNotes] = useState(false);
  const [showAccountRepName, setShowAccountRepName] = useState(false);
  const [showAccountRepEmail, setShowAccountRepEmail] = useState(false);

  const [facilityisLKActive, setFacilityisLKActive] = useState(
    props.comcode.isActive
  );

  const [pacesetter, setPacesetter] = useState(props.comcode.pacesetter);
  const [franchiseNotes, setFranchiseNotes] = useState(props.comcode.lkNotes);
  const [accountRepName, setAccountRepName] = useState(props.comcode.LKSIAccountRepName);
  const [accountRepEmail, setAccountRepEmail] = useState(props.comcode.LKSIAccountRepEmail);

  const franshiseRow = {
    dim_franchiseID: dim_franchiseID,
    comID: comID,
    comcode: comcode,
    locations: locations,
    pacesetter: pacesetter,
    isActive: isActive,
    LKSIAccountRepName: accountRepName,
    LKSIAccountRepEmail: accountRepEmail,
    franchiseNotes: franchiseNotes,
  };

  const handleShowFranchiseNotes = () => setShowFranchiseNotes(true);
  const handleCloseFranchiseNotes = () => setShowFranchiseNotes(false);

  const handleShowAccountRepName = () => setShowAccountRepName(true);
  const handleCloseAccountRepName = () => setShowAccountRepName(false);

  const handleShowAccountRepEmail = () => setShowAccountRepEmail(true);
  const handleCloseAccountRepEmail = () => setShowAccountRepEmail(false);

  const handleFacilityisLKActive = (event) => {
    const newSetting = event.target.checked;
    setFacilityisLKActive(newSetting);
  };

  const handlePacesetter = (event) => {
    const newSetting = event.target.checked;
    setPacesetter(newSetting);
  };

  const handleLocationNotesChanged = (event) => {
    const newNotes = event.target.value;
    setFranchiseNotes(newNotes);
  };

  const handleAccountRepNameChanged = (event) => {
    const newAccountRepName = event.target.value;
    setAccountRepName(newAccountRepName);
  };

  const handleAccountRepEmailChanged = (event) => {
    const newAccountRepEmail = event.target.value;
    setAccountRepEmail(newAccountRepEmail);
  };

  const handleFranchiseUpdated = (e) => {
    const franchiseRowData = {
      dim_franchiseID: dim_franchiseID,
      pacesetter: pacesetter,
      lkNotes: franchiseNotes,
      isLKSIActive: facilityisLKActive,
      LKSIAccountRepName: accountRepName,
      LKSIAccountRepEmail: accountRepEmail
    };

    // console.log( "in handleFranchisUpdate, here's the franchiseRowData: ", franchiseRowData);
    handleUpdateLKFieldsDimFranchise(franchiseRowData);
  };

  const theme = createTheme();

  theme.typography.comcodeRow = {
    fontSize: "1.25rem",
  };

  // console.log("comcode",comcode)
  const locationsArray = locations.map((location) => {
    // console.log("location", location);

    const element = (
      <LocationRow
        key={location.dim_facilityID}
        location={location}
        locationLKSINotes={location.locationLKSINotes}
        dim_facilityID={location.dim_facilityID}
        facilityID={location.facilityID}
        locationLKSIEmail={location.locationLKSIEmail}
        locationisLKSIActive={location.locationactive}
        franchiseRowData={franshiseRow}
        handleUpdateLKFieldsDimFacility_Location={
          handleUpdateLKFieldsDimFacility_Location
        }
        handleAddUpdateFacilitySite={handleAddUpdateFacilitySite}
        handleRemoveFacilitySite={handleRemoveFacilitySite}
      />
    );
    return element;

  });

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 90%;`
  );
  return (
    <TableContainer classnamecomponent={Paper}>
      <Table
        className="centered collapsibleTable"
        aria-label="collapsible table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" width="10%">
              <ThemeProvider theme={theme}>
                <Typography variant="comcodeRow">
                  <b>
                    {comcodeUpperCase} ({comID})
                  </b>
                </Typography>
              </ThemeProvider>
            </TableCell>
            <TableCell width="20%" align="center">
              <ThemeProvider theme={theme}>
                <Typography variant="comcodeRow">
                  Franchise Active :
                  <Checkbox
                    value={facilityisLKActive == 1}
                    checked={facilityisLKActive == 1}
                    onChange={(e) => handleFacilityisLKActive(e, {})}
                  ></Checkbox>
                </Typography>
              </ThemeProvider>
            </TableCell>
            <TableCell width="15%">
              <ThemeProvider theme={theme}>
                <Typography variant="comcodeRow">
                  PaceSetter :{" "}
                  <Checkbox
                    value={pacesetter == 1}
                    checked={pacesetter == 1}
                    onChange={(e) => handlePacesetter(e, {})}
                  ></Checkbox>
                </Typography>
              </ThemeProvider>
            </TableCell>

            <TableCell width="15%">
              <TextField
                fullWidth
                value={accountRepName || ''}
                size="small"
                helperText={" Account Rep "}
                onClick={handleShowAccountRepName}
              ></TextField>
              <Modal
                show={showAccountRepName}
                onHide={handleCloseAccountRepName}
                className="comcodeNotesModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{comcode} Account Rep</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <StyledTextarea */}
                  <AccountRepNameTextArea
                    placeholder="Notes"
                    defaultValue={accountRepName || ''}
                    onChange={handleAccountRepNameChanged}
                  ></AccountRepNameTextArea>

                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseAccountRepName}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </TableCell>


            <TableCell width="15%">
              <TextField
                fullWidth
                value={accountRepEmail || ''}
                size="small"
                helperText={ " Account Rep Email "}
                onClick={handleShowAccountRepEmail}
              ></TextField>
              <Modal
                show={showAccountRepEmail}
                onHide={handleCloseAccountRepEmail}
                className="comcodeNotesModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{comcode} Rep Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <StyledTextarea */}
                  <AccountRepEmailTextArea
                    placeholder="Rep Email"
                    defaultValue={accountRepEmail || ''}
                    onChange={handleAccountRepEmailChanged}
                  ></AccountRepEmailTextArea>

                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseAccountRepEmail}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </TableCell>


            <TableCell width="15%">
              <TextField
                fullWidth
                value={franchiseNotes || ''}
                size="small"
                helperText={comcodeUpperCase + " Notes"}
                onClick={handleShowFranchiseNotes}
              ></TextField>
              <Modal
                show={showFranchiseNotes}
                onHide={handleCloseFranchiseNotes}
                className="comcodeNotesModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{comcode} Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <StyledTextarea */}
                  <FranchiseNotesTextArea
                    placeholder="Notes"
                    defaultValue={franchiseNotes || ''}
                    onChange={handleLocationNotesChanged}
                  ></FranchiseNotesTextArea>

                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseFranchiseNotes}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </TableCell>



            <TableCell width="3.33%"></TableCell>
            <TableCell width="15%" align="left">
              <Button
                variant="contained"
                onClick={(e) => handleFranchiseUpdated(e, {})}
              >
                <ThemeProvider theme={theme}>
                  <Typography variant="comcodeRow">Update </Typography>
                </ThemeProvider>
              </Button>
            </TableCell>
            <TableCell width="15%" />
          </TableRow>
        </TableHead>
        <TableBody>{locationsArray}</TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledTextArea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 90%;`
);

export function FranchiseNotesTextArea(props) {
  return (
    <StyledTextArea
      placeholder="asdf"
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      autoFocus
      onFocus={function (e) {
        var val = e.target.value;
        e.target.value = "";
        e.target.value = val;
      }}
    ></StyledTextArea>
  );
}

export function AccountRepNameTextArea(props) {
  return (
    <StyledTextArea
      placeholder="asdf"
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      autoFocus
      onFocus={function (e) {
        var val = e.target.value;
        e.target.value = "";
        e.target.value = val;
      }}
    ></StyledTextArea>
  );
}

export function AccountRepEmailTextArea(props) {
  return (
    <StyledTextArea
      placeholder="asdf"
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      autoFocus
      onFocus={function (e) {
        var val = e.target.value;
        e.target.value = "";
        e.target.value = val;
      }}
    ></StyledTextArea>
  );
}
