import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { useState } from "react";


export default function FacilitySiteRow(props) {

  const facility = props.facility;
  const handleAddUpdateFacilitySite = props.handleAddUpdateFacilitySite;
  const handleRemoveFacilitySite = props.handleRemoveFacilitySite;

  console.log("facility in facilitysiteroe: ", facility)
  const facility_id = facility.facility_id;
  const facility_source = facility.facilitysource;
  const facility_site_id = facility.facility_site_id;

  const [numFields, setNumFields] = useState(facility.numFields);
  const [facilitySpace, setFacilitySpace] = useState(facility.space)
  const [facilityName, setFacilityName] = useState(facility.facility)
  const [indoorAvail, setIndoorAvail] = useState(facility.indooravailable);
  const [outdoorAvail, setOutdoorAvail] = useState(facility.outdooravailable);
  const [facilityActive, setfacilityActive] = useState(facility.facilityactive);

  const [rowEdited, setRowEdited] = useState(false);


  // console.log("facility in FacilitySiteRow: ", FacilitySiteRow)
  const handleFacilityName = (event) => {
    const newSetting = event.target.value;
    console.log(event.target.value);
    setFacilityName(newSetting);
    console.log(facilityName)
    setRowEdited(true);
  }


  const handleNumFieldsInput = (event) => {
    setNumFields(event.target.value);
    setRowEdited(true);
  };

  const handleSpaceInput = (event) => {
    setFacilitySpace(event.target.value);
    setRowEdited(true);
  };

  const handleIndoorAvail = (event) => {
    const newSetting = event.target.checked;
    console.log("newSetting: ", newSetting);
    if (newSetting === true) {
      setIndoorAvail(1);
    }
    else {
      setIndoorAvail(0);
    }
    setRowEdited(true);
  }

  const handleOutdoorAvail = (event) => {
    const newSetting = event.target.checked;
    if (newSetting === true) {
      setOutdoorAvail(1);
    }
    else {
      setOutdoorAvail(0);
    }
    setRowEdited(true);
  }

  const handleFacilityActive = (event) => {
    const newSetting = event.target.checked;

    if (newSetting === true) {
      setfacilityActive(1);
    }
    else {
      setfacilityActive(0);
    }
    setRowEdited(true);
  }


  const handleFacilitySiteUpdated = (e) => {

    const facilitySiteRow = {
      facility_site_id: facility_site_id,
      facilityName: facilityName,
      facility_numFields: numFields,
      facilitySpace: facilitySpace,
      facilityIndoorAvail: indoorAvail,
      facilityOutdoorAvail: outdoorAvail,
      facility_Active: facilityActive,
      facility_source: facility_source,
      facility_id: facility_id
    }
    console.log("in handleFacilitySiteUpdated: ", facilitySiteRow)


    handleAddUpdateFacilitySite(facilitySiteRow);
    setRowEdited(false);
    
  }

  const handleFacilitySiteDeleted = (e) => {

    console.log("in handleFacilitySiteDeleted: ", facility_site_id)
    handleRemoveFacilitySite(facility_site_id);
  }

  
  return (
    <React.Fragment>
      <TableRow >
        <TableCell>
          <TextField
            type="text"
            id="textfield"
            name="namefield"
            onChange={handleFacilityName}
            defaultValue={facilityName}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          ></TextField>

        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <TextField
            type="number"
            id="numFields"
            name="numFields"
            onChange={handleNumFieldsInput}
            defaultValue={numFields}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <TextField
            type="number"
            id="space"
            name="space"
            onChange={handleSpaceInput}
            defaultValue={facilitySpace}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          {/* <Checkbox value={indoorAvail == 1} checked={indoorAvail == 1} onChange={e => handleIndoorAvail(e, {})}> */}
          <Checkbox checked={indoorAvail === 1} onChange={e => handleIndoorAvail(e, {})}>
          </Checkbox>
        </TableCell>
        <TableCell align="center">
          {/* <Checkbox value={outdoorAvail == 1} checked={outdoorAvail == 1} onChange={e => handleOutdoorAvail(e, {})}> */}
          <Checkbox checked={outdoorAvail === 1} onChange={e => handleOutdoorAvail(e, {})}>
          </Checkbox>
        </TableCell>
        <TableCell align="center">
          {/* <Checkbox value={facilityActive == 1} checked={facilityActive == 1} onChange={e => handleFacilityActive(e, {})}> */}
          <Checkbox checked={facilityActive === 1} onChange={e => handleFacilityActive(e, {})}>
          </Checkbox>
        </TableCell>
        <TableCell align="center">
          {/* <Button variant="contained" onClick={e => handleFacilitySiteUpdated(e, {})}>Add/Update</Button> */}
          {rowEdited ? <Button variant="contained" onClick={e => handleFacilitySiteUpdated(e, {})}>Add/Update</Button> : <Button variant="outlined" onClick={e => handleFacilitySiteDeleted(e, {})}> Delete</Button>}
        </TableCell>

      </TableRow>
    </React.Fragment>
  );
}
