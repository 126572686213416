import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from './components/App';
import dayjs from 'dayjs';

const firebaseConfig = {
    apiKey: "AIzaSyB_C_XVJH9qIAwAkmi6WnRjeMDoOu9w7U8",
    authDomain: "seasonmanager-422e9.firebaseapp.com",
    projectId: "seasonmanager-422e9",
    storageBucket: "seasonmanager-422e9.appspot.com",
    messagingSenderId: "299292064991",
    appId: "1:299292064991:web:37ca346010007af68ffd6a",
    measurementId: "G-SW94GKZRY4"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter><App /></BrowserRouter> 

);

